<template>
  <div>
    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      class="mobileSideMenu"
      size="50%"
    >
      <div>
        <div class="drawer-head _flex align-items_center _justify_center">
          <div class="drawerBtn">
            <div class="btns1">{{username}}</div>
          </div>
        </div>
        <el-menu
          :default-active="activeName"
          active-text-color="#ffd04b"
          background-color="#2C59A3"
          text-color="#fff"
          class="el-menu-vertical-demo userMenu"
        >
          <el-menu-item
            v-for="(item, index) in menuList"
            @click.native="handleSelect(item)"
            :key="index"
            :index="item.code"
          >
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
    <login ref="login"  @refreshPage="refreshPage"></login>
  </div>
</template>
<script>
import { URL } from "@/urlpath.js";
import login from "@/components/login/login";
export default {
  components: {
    login
  },
  props:{
    username:{
      type: String,
      default: "无",
    }
  },
  data() {
    return {
      activeName: "Home",
       islogin:false,//是否登录
       menuList: [
        {
          name: "网站首页",
          code: "Home",
        },
        {
          name: "用户中心",
          code: "gs",
        },
        {
          name: "个人资料",
          code: "cs",
        },
        {
          name: "我的收藏",
          code: "zl",
        },
        {
          name: "我的预约",
          code: "sb",
        },
        {
          name: "服务记录",
          code: "fw",
        },
        {
          name: "浏览历史",
          code: "qy",
        },
        {
          name: "退出登录",
          code: "logout",
        }
      ],
      drawer: false,
    };
  },
  created() {
    this.initData = this.$route.query;
      console.log(this.initData);
      this.activeName = this.initData.type;
      this.refreshPage()
  },
  methods: {
     // 登录
    login(){
      this.drawer = false;
      this.$refs.login.showLogin()
    },
    // 注册
    register(){
      this.drawer = false;
      this.$refs.login.showRegist()
    },
     // 刷新
    refreshPage(){
      if(sessionStorage.getItem('Authorization')){
        this.islogin=true;
      }
    },
    // 跳转用户中心
    userManage(){
      this.$router.push({ 
        name: "userManage"
      });
    },
    showMenu(){
  this.drawer = true;
    },
    handleSelect(e) {
      console.log(e);
      this.drawer = false;
      if(e.code== "Home"){
        this.activeName = e.code;
          this.$router.push({ 
            name: e.code,
            query:{
              name:e.name,
              type:e.code
            }
          });
      }else if(e.code== "logout"){
        this.$emit("logout")
      }else{
        let obj = {
          name:e.name,
          active:"",
        }
        this.$emit("menuClick",obj)
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.drawer-head{
  height: 70px;
  background: #2C59A3;
}
.drawerBtn{
  width: 80%;
  background: #116BFF;
  border-radius:20px ;
}
.drawerBtn  .btns {
  width: 50%;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}
.drawerBtn  .btns1 {
  width: 100%;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}
</style>