 <template>
  <div>
    <div style="width:500px">
      <el-input prefix-icon="el-icon-search"   v-model="searchName"
        @keyup.enter.native="searchAllCompany" placeholder="查询我的留言"></el-input>
    </div>
    <div class="_marginTop" style="height:500px">
      <el-table :data="form.rows" style="width: 100%">
      <el-table-column prop="createTime" label="留言日期" width="180">
      </el-table-column>
      <el-table-column prop="content" label="留言内容"> </el-table-column>
       <el-table-column prop="feedbackContent" label="平台回复">
      </el-table-column>
       <el-table-column prop="name" width="80" label="姓名">
      </el-table-column>
       <el-table-column prop="phone" width="180" label="电话">
      </el-table-column>
       <el-table-column prop="status" width="180" label="状态">
      </el-table-column>
    </el-table>
    </div>
   <div class="_marginTop" style="    text-align: center;" v-if="form.rows.length!=0">
                <el-pagination background layout="prev, pager, next" :total="form.total" @current-change="pageChange"> </el-pagination>
            </div>
  </div>
</template>

   <script>
  import { URL, BASEURL } from "@/urlpath.js";
export default {
  data() {
    return {
      form:{
          rows:[]
      },
      searchName:"",
        loading:false,
      page:1,
       size:12
    };
  },
    created() {
    this.getDetail();
  },
  methods: {
     getDetail() {
       this.loading = true;
       this.$ajax.post(URL.USER_MESSAGEBACK,{
          name:this.searchName,
         pageNum:this.page,
        pageSize:this.size,
       }).then((res) => {
        console.log(res);
         this.loading = false;
        if (res.data.code == "0000") {
          this.form = res.data.obj;
        }else{
            this.$message.error('服务器错误!');
        }
      }).catch(err=>{
          this.loading = false;
        });
    },
     // 搜所
    searchAllCompany(){
      this.getDetail()
    },
      // 分页
    pageChange(e){
      console.log(e)
      this.page = e;
    },
  },
};
</script>

<style scoped>
</style>
