<template>
  <div class="hello">
    <div class="aside_box">
      <div class="free_blue"  @click="register"><img src="@/assets/img/float/fly.png"/>
        <div style="margin-top:5px;font-size:12px">免费注册</div>
      </div>
      <div class="bottomBox">
        <div class="wechatBox"  @mouseenter="wechatEnter" @mouseleave="wechatLeave">
         <img src="@/assets/img/float/wechat.png" />
         <img src="@/assets/img/weixin.jpg" v-if="wechatShow" class="wechat">
        </div>
        <div class="wechatBox" @mouseenter="hotlineEnter" @mouseleave="hotlineLeave">
          <img src="@/assets/img/float/tel.png" />
          <div class="tel" v-if="telShow">
            16601116787
          </div>
        </div>
        <div class="wechatBox"  @mouseenter="qqEnter" @mouseleave="qqLeave">
          <img src="@/assets/img/float/QQ.png" style="height:35px" />
          <img src="@/assets/img/qqCode.jpg" class="wechat" v-if="qqShow" style="width: 130px!important;height: 150px!important;">
        </div>
      </div>
      <div>
          <el-backtop target=".hello" >
        <!-- <img src="@/assets/image/toppage.png" /> -->
         </el-backtop>
        </div>
    </div>
     <login ref="login"   @refreshPage="refreshPage"></login>
  </div>
</template>

<script>
import login from "@/components/login/login";
export default {
  name: 'home',
   components: {
    login
  },
   data() {
    return {
      islogin:false,//是否登录
      screenHeight: document.documentElement.clientHeight,
      hotline:false,
      baseUrl:"",
      telShow:false, //电话
      wechatShow:false, //微信二维码
      qqShow:false //微信二维码
    };
  },
  created(){
    this.refreshPage()
  },
  methods:{
    // 注册
    register(){
      this.$refs.login.showRegist()
    },
        // 刷新
    refreshPage(){
      if(sessionStorage.getItem('Authorization')){
        this.islogin=true;
      }
    },
    hotlineEnter(){
      console.log("移入")
      this.telShow=true
    },
    hotlineLeave(){
       console.log("移出")
       this.telShow=false
    },
    wechatEnter(){
      console.log("移入")
      this.wechatShow=true
    },
    wechatLeave(){
       console.log("移出")
       this.wechatShow=false
    },
    qqEnter(){
      console.log("移入")
      this.qqShow=true
    },
    qqLeave(){
       console.log("移出")
       this.qqShow=false
    },
  }
};
</script>

<style scoped>
.hello .aside_box {
  position: fixed;
  top: 50%;
  right: 0px;
  z-index: 555;
  transform: translateY(-30%);
  width: 75px;
}
.free_blue{
  background: #116BFF;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  cursor: pointer;
      font-size: 14px;
      border-radius: 10px 10px 0 0;
}
.hello .aside_box img {
  width: 30px;
  height: 30px;
}
.bottomBox{
   box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    margin-top:20px;
    background: #fff;
     border-radius: 0px 0px 10px 10px;
}
.wechatBox{
   padding: 15px 0;
   border-bottom:1px solid #eee ;
   text-align: center;
    cursor: pointer;
    position: relative;
}
.wechat{
   position: absolute;
    top: 0px;
     right: 120px;
     padding: 5px;
    width: 110px!important;
    height: 110px!important;
   box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
.tel{
     position: absolute;
    top: 0px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    background: #fff;
        font-weight: bold;
    right: 100px;
    width: 200px;
    line-height: 65px;
    height: 65px;
}
</style>
