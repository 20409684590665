<template>
  <div class=""  v-loading="loading">
    <div class="searchWidth">
      <el-input
        prefix-icon="el-icon-search"
        v-model="searchName"
        @keyup.enter.native="searchAllCompany"
        placeholder="查询我的收藏"
      ></el-input>
    </div>
    <!-- 服务板块 -->
    <div>
      <div class="_marginTop">
        <div class="_flex _wrap">
          <div class="serve_card" v-for="(item, index) in form.rows" :key="index" @click="goDetail(item,'')">
            <div class="blueBlock"></div>
            <div class="serve_title">{{item.commodityName}}</div>
            <div class="serve_desc">{{item.commodityBrief}}</div>
            <div>
              <div v-if="item.price!='0'">
                <span style="color: #ff0000; font-size: 18px">{{item.price}}</span>
              <span style="color: #888; font-size: 14px; margin-left: 5px"
                >元起</span
              >
              </div>
              <div v-else>
                <span style="color: #ff0000; font-size: 18px">价格面议</span>
              </div>
            </div>
          </div>
        </div>
         <div class="_marginTop" style="    text-align: center;" v-if="form.rows.length!=0">
                <el-pagination background layout="prev, pager, next" :total="form.total" @current-change="pageChange"> </el-pagination>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
export default {
  name: "home",
  data() {
    return {
      loading:false,
      searchName:"",
      form: {
        rows:[]
      },
       page:1,
       size:12
    };
  },
   created() {
    this.getDetail();
  },
  methods: {
     getDetail() {
         this.loading = true;
       this.$ajax.post(URL.USER_COLLECT,{
         name:this.searchName,
         pageNum:this.page,
        pageSize:this.size,
       }).then((res) => {
        console.log(res);
        this.loading = false;
        if (res.data.code == "0000") {
          this.form = res.data.obj;
        }else{
            this.$message.error('服务器错误!');
        }
      }).catch(err=>{
          this.loading = false;
        });
    },
    // 搜所
    searchAllCompany(){
      this.getDetail()
    },
     // 详情
    goDetail(e,type) {
      console.log(e)
      this.$router.push({
        name: "companyRegister",
        query: {
          id:e.commodityId,
          name:e.name,
          type:type
        },
      });
    },
      // 分页
    pageChange(e){
      console.log(e)
      this.page = e;
    },
  },
};
</script>

<style scoped>

.blueBlock {
  position: absolute;
  left: 0px;
  top: 18px;
  width: 10px;
  height: 15px;
  background: #116bff;
}
.serve_card .serve_title {
  font-weight: bold;
}
.serve_card .serve_desc {
  color: #888;
  font-size: 14px;
  margin-top: 5px;
  height: 50px;
  overflow: hidden;
}
@media (min-width:768px){
  .serve_card {
  width: 20%;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  height: 100px;
  margin-top: 10px;
  margin-right: 3%;
  position: relative;
  cursor: pointer;
}
.serve_card:nth-child(4n) {
  margin-right: 0px;
}
  .searchWidth{
    width:500px;
  }
}
@media (max-width:768px){
.serve_card {
  width: 38%;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  height: 100px;
  margin-top: 10px;
  margin-right: 3%;
  position: relative;
  cursor: pointer;
}
.serve_card:nth-child(2n) {
  margin-right: 0px;
}
}
</style>
