 <template>
  <div v-loading="loading">
    <div style="height:500px">
      <el-table :data="form.rows" style="width: 100%">
      <el-table-column prop="createTime" label="浏览时间" width="180">
      </el-table-column>
      <el-table-column prop="commodityName" label="浏览项目"> </el-table-column>
    </el-table>
    </div>
  <div v-if="form.rows.length!=0" style="    text-align: center;">
      <el-pagination background layout="prev, pager, next" :total="form.total" @current-change="pageChange"> </el-pagination>
  </div>
  </div>
</template>

  <script>
  import { URL, BASEURL } from "@/urlpath.js";
export default {
  data() {
    return {
      form:{
          rows:[]
      },
        loading:false,
      page:1,
       size:12
    };
  },
    created() {
    this.getDetail();
  },
  methods: {
     getDetail() {
       this.loading = true;
       this.$ajax.post(URL.USER_BROWSER,{
         pageNum:this.page,
        pageSize:this.size,
       }).then((res) => {
        console.log(res);
         this.loading = false;
        if (res.data.code == "0000") {
          this.form = res.data.obj;
        }else{
            this.$message.error('服务器错误!');
        }
      }).catch(err=>{
          this.loading = false;
        });
    },
      // 分页
    pageChange(e){
      console.log(e)
      this.page = e;
    },
  },
};
</script>

<style scoped>
</style>
