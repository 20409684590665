<template>
  <div class="">
    <headers :activeName="menuActive"></headers>
    <div class="main">
      <!-- 导航 -->
      <div class="nav">
        <span>首页></span>
        <span>商品搜索</span>
      </div>
      <!-- 内容块 -->
      <div class="_flex">
        <div class="content-left">
          <div  class="_flex _wrap">
            <div class="serve_card" v-for="(item, index) in form.rows" :key="index" @click="goDetail(item,'')">
            <div class="blueBlock"></div>
            <div class="serve_title">{{item.name}}</div>
            <div class="serve_desc">{{item.brief}}</div>
            <div>
              <div v-if="item.price!='0'">
                <span style="color: #ff0000; font-size: 18px">{{item.price}}</span>
              <span style="color: #888; font-size: 14px; margin-left: 5px"
                >元起</span
              >
              </div>
              <div v-else>
                  <span style="color: #ff0000; font-size: 18px">价格面议</span>
              </div>
            </div>
          </div>
          </div>
         <!-- 广告 -->
         <img
        class="_marginTop"
        v-if="banner_adv.length!=0"
        :src="banner_adv[0].imgUrl"
        style="width: 100%; border-radius: 10px"
      />
          <!-- <div class="_marginTop">
            <div class="tj_title">相关推荐：</div>
            <div class="tj_content">
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
            </div>
          </div> -->
          <div class="_marginTop">
            <!-- <div class="tj_title _flex" style="align-items: baseline;">
              <div>标签：</div>
              <div style="font-weight: normal;">
                <span class="tj_span">专精特新企业</span>
                <span class="tj_span">专精特新企业</span>
                <span class="tj_span">专精特新企业</span>
              </div>
            </div> -->
            <div style="color: #888">
              版权声明:本站刑载的所有内容[包括但不限于文字、图片、视频和音频等]所有权均归公司，未经作者许可，任何人不得转载。
            </div>
          </div>
        </div>
        <div class="content-right"  v-show="deviceType=='PC'">
          <appointment></appointment>
          <!-- 最新资讯 -->
          <news></news>
        </div>
      </div>
    </div>
    <footers class="_marginTop" :activeName="menuActive"></footers>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import {
		isDeviceType
	} from '@/utils.js'
import appointment from "@/components/appointment/appointment";
import news from "@/components/information/news";
import headers from "@/components/serveHead/serveHead";
import footers from "@/components/pageBottom/pageBottom";
export default {
  components: {
    headers,
    news,
    appointment,
    footers,
  },
  data() {
    return {
      form: {
        rows:[]
      },
        deviceType:"",
       banner_adv:[],//广告
      menuActive: "",
       page:1,
       size:50
    };
  },
  watch: {
    $route(to, from) {
      // 对路由变化作出响应...
      console.log(" 对路由变化作出响应...");
      this.getDetail();
    },
  },
  created() {
      this.deviceType = isDeviceType()
    this.getDetail();
     this.getAdvertisement()
  },
  methods: {
    getDetail() {
      this.initData = this.$route.query;
      console.log(this.initData);
      this.$ajax.post(URL.searchData,{
         name:this.initData.name,
         pageNum:this.page,
        pageSize:this.size,
      }).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form = res.data.obj;
          this.$forceUpdate();
        }
      });
    },
     // 获取广告
    getAdvertisement() {
      let id='90';
      //获取广告
      this.$ajax.get(URL.findInfo_adv+'/'+id).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.banner_adv = res.data.obj;
          // console.log(this.adverList);
        }
      });
    },
     // 详情
    goDetail(e,type) {
      console.log(e)
      this.$router.push({
        name: "companyRegister",
        query: {
          id:e.id,
          name:e.name,
          type:type
        },
      });
    },
  },
};
</script>

<style scoped>
.nav {
  padding: 20px 0;
  color: #888;
  font-size: 14px;
}
.content-left {
  width: 80%;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
  min-height: 500px;
}
.content-right {
  width: 20%;
  min-height: 500px;
  padding: 0 15px;
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}
.content-desc {
  font-size: 14px;
  color: #888;
}
.tj_title {
  color: #116bff;
  font-weight: bold;
  padding-bottom: 10px;
}
.tj_content {
  font-size: 13px;
  line-height: 28px;
}
.tj_span {
  font-size: 13px;
  margin-right: 10px;
}
.serve_card {
      width: 20%;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 8px;
    height: 100px;
    margin-top: 10px;
    margin-right: 1%;
    position: relative;
    cursor: pointer;
}
.serve_card:nth-child(4n) {
  margin-right: 0px;
}
.blueBlock {
  position: absolute;
  left: 0px;
  top: 18px;
  width: 10px;
  height: 15px;
  background: #116bff;
}
.serve_card .serve_title {
  font-weight: bold;
}
.serve_card .serve_desc {
  color: #888;
  font-size: 14px;
  margin-top: 5px;
  height: 50px;
  overflow: hidden;
}
@media (min-width:768px){
.main {
  width: 1200px;
  margin: 0 auto;
}
.content-left {
  width: 80%;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
  min-height: 500px;
}
}
@media (max-width:768px){
.content-left {
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
  min-height: 500px;
}
}
</style>
