<template>
  <div class="" v-loading="loading" style="padding-right:10px">
    <div  class="top-box">
      <div class="top-left-box">
        <div class="_flex _justify_between align-items_center">
          <div class="title">用户信息</div>
          <img src="@/assets/img/user/rightMore.png" class="rightMore"  @click="userMore"/>
        </div>
        <div style="margin-top: 30px" v-if="form.userInfo">
          <div class="_flex mobileLayout">
            <div class="mobile_userimg">
              <img
                v-if="form.userInfo.userPhoto"
                :src="form.userInfo.userPhoto"
                class="user-admin"
              />
              <img
                v-else
                src="@/assets/img/user/user_big.png"
                class="user-admin"
              />
            </div>
            <div class="_flex mobileLayout">
              <div class="infoLeft">
                <div class="accountLi _flex align-items_center">
                  <div class="label">账户</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.username">
                      {{ form.userInfo.username }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <div class="accountLi _flex align-items_center">
                  <div class="label">昵称</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.userNickname">
                      {{ form.userInfo.userNickname }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <div class="accountLi _flex align-items_center">
                  <div class="label">真实姓名</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.realName">
                      {{ form.userInfo.realName }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <div class="accountLi _flex align-items_center">
                  <div class="label">联系电话</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.userPhone">
                      {{ form.userInfo.userPhone }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <div class="accountLi _flex align-items_center">
                  <div class="label">邮箱</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.userPhone">
                      {{ form.userInfo.email }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <div class="accountLi _flex align-items_center">
                  <div class="label">微信号</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.userPhone">
                      {{ form.userInfo.userOpenid }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <el-button type="success" size="mini" style="margin-top:10px" v-if="form.userInfo.userOpenid" @click.native="unbindWeChat">解除微信绑定</el-button>
                <el-button type="success" size="mini" style="margin-top:10px" v-else @click.native="clickWeChat">绑定微信</el-button>
              </div>
              <div class="infoRight">
                <div class="accountLi _flex align-items_center">
                  <div class="label">企业名称</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.companyName">
                      {{ form.userInfo.companyName }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <div class="accountLi _flex align-items_center">
                  <div class="label">企业地址</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.companyAddress">
                      {{ form.userInfo.companyAddress }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <div class="accountLi _flex align-items_center">
                  <div class="label">办公电话</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.companyPhone">
                      {{ form.userInfo.companyPhone }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
                <div class="accountLi _flex align-items_center">
                  <div class="label">企业邮箱</div>
                  <div>
                    <div class="td_content" v-if="form.userInfo.companyEmail">
                      {{ form.userInfo.companyEmail }}
                    </div>
                    <div class="noWrite" v-else>未填写</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="top-right-box">
        <div class="_flex _justify_between align-items_center">
          <div class="title">我的收藏</div>
          <img src="@/assets/img/user/rightMore.png" class="rightMore" @click="collectMore"/>
        </div>
        <div class="collectBox" style="margin-top: 30px">
          <div
            class="collectLi _flex align-items_center _justify_between"
            v-for="(item, index) in form.userCollect"
          >
            <div class="_flex align-items_center">
              <div class="cornor"></div>
              <div>{{ item.commodityName }}</div>
            </div>
            <div>
             <div v-if="item.price!='0'">
                <span style="color: red">{{ item.price }}</span>
              <span style="color: #888; font-size: 12px">元起</span>
             </div>
             <div v-else>
              <span style="color: red">价格面议</span>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle-box">
      <div class="_flex _justify_between align-items_center">
        <div class="title">服务记录</div>
        <img src="@/assets/img/user/rightMore.png" class="rightMore" @click="serviceMore"/>
      </div>
      <div style="margin-top: 10px">
        <el-table :data="form.serviceInfo" style="width: 100%">
          <el-table-column prop="contractCode" label="合同编号" width="180">
          </el-table-column>
          <el-table-column prop="serviceName" label="服务名称"> </el-table-column>
          <el-table-column prop="serviceDate" width="250" label="服务日期"> </el-table-column>
          <el-table-column prop="money" width="180" label="合同金额（元）">
          </el-table-column>
          <el-table-column prop="status" width="180" label="服务进度">
          </el-table-column>
          <el-table-column label="评价" width="180">
            <template slot-scope="scope">
             <div>
              <el-rate v-model="scope.row.evaluationNumber" disabled></el-rate>
           </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="bottom-box">
      <div class="title">资讯政策</div>
      <div class="_flex _wrap">
        <div
        class="newsLi _flex align-items_center"
        v-for="(item, index) in form.articles"
        @click="goDetail(item)"
      >
        <img
          :src="item.imgUrl"
          style="width: 100px; margin-right: 20px; height: 100px"
        />
        <div>
          <div class="li-title">{{ item.title }}</div>
          <div class="li-desc">{{ item.description }}</div>
        </div>
      </div>
      </div>
    </div>
    <div style="height:100px"></div>
    <el-dialog
    append-to-body
  title="微信绑定"
  :visible.sync="showWechat"
  width="30%"
  :before-close="handleClose">
   <iframe :src="wechatUrl" style="width:100%;height: 405px;border: 0px;"></iframe>
</el-dialog>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import { getUrlParam } from "@/utils.js";
export default {
  name: "home",
  data() {
    return {
      loading: false,
      showWechat:false,
      wechatUrl:"",
      form: {
        userInfo:{}
      },
    };
  },
  created() {
    this.getDetail();
    if(getUrlParam("code")){
      let code = getUrlParam("code")
      let state = getUrlParam("state")
      this.bindWechat(code,state)
    }
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.$ajax
        .get(URL.USER_CENTER)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.code == "0000") {
            this.form = res.data.obj;
          } else {
            this.$message.error("服务器错误!");
            sessionStorage.clear();
            setTimeout(()=>{
            this.$router.push({
              path:'/'
            })
          },800)
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    clickWeChat(){
       this.loading = true;
      this.$ajax
        .get(URL.BIND_WECHAT+`?redirectUrl=https://www.zhihuovip.com/userManage`)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.code == "0000") {
            this.showWechat = true;
            this.wechatUrl = res.data.obj;
          } else {
            this.$message.error("服务器错误!");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 解绑微信
    unbindWeChat(){
      this.$ajax
        .post(URL.UNBIND_WECHAT)
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            this.getDetail()
            this.$forceUpdate()
             this.$message({
          message: '解除微信绑定成功',
          type: 'success'
        });
          } else {
            this.$message.error("服务器错误!");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 绑定微信
    bindWechat(code,state){
       this.$ajax
        .post(URL.BIND_WECHATAPI,{
          code:code,
          state:state
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
             this.$message({
          message: '微信绑定成功',
          type: 'success'
        });
        setTimeout(()=>{
          window.location.href="https://www.zhihuovip.com/userManage"
        },500)
          } else {
            this.$message.error("服务器错误!");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleClose(){
      this.showWechat = false;
    },
    userMore(){
      let obj = {
        active:1,
        title:'个人资料',
      }
      this.$emit('more',obj)
    },
     collectMore(){
        let obj = {
        active:4,
        title:'我的收藏',
      }
       this.$emit('more',obj)
    },
     serviceMore(){
        let obj = {
        active:3,
        title:'服务记录',
      }
       this.$emit('more',obj)
    },
    // 资讯政策详情
    goDetail(e) {
      console.log(e);
      this.$router.push({
        name: "service",
        query: {
          id: e.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.user-admin {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.collectLi {
  height: 55px;
  border-bottom: 1px solid #eee;
}
.accountLi .label {
  width: 76px;
  white-space: nowrap;
}


.td_content{
      width: 100%;
    font-size: 14px;
    color: #666;
    word-break: break-all;
}
.cornor {
  width: 30px;
  height: 10px;
  background: #2170e9;
  margin-right: 10px;
}

.collectBox{
    height: 230px;
    overflow-y: scroll;
    padding-right: 10px;
}
.collectBox::-webkit-scrollbar {
				/*滚动条整体样式*/
				width: 5px;
				/*高宽分别对应横竖滚动条的尺寸*/
				height: 1px;
			}

			.collectBox::-webkit-scrollbar-thumb {
				/*滚动条里面小方块*/
				border-radius: 10px;
				box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				background: #f5f3f3;
			}

			.collectBox::-webkit-scrollbar-track {
				/*滚动条里面轨道*/
				box-shadow: inset 0 0 5px rgba(97, 96, 96, 0.2);
				border-radius: 10px;
				background: #fff;
			}
.middle-box {
  background: url("../../../assets/img/user/userbg.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 100%;
  margin-top: 2%;
  height: 310px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 5px;
}

.title {
  color: #2170e9;
  font-weight: bold;
}
.li-title {
  font-weight: bold;
  font-size: 16px;
}
.li-desc {
  color: #888;
  font-size: 14px;
  margin-top: 10px;
}
.noWrite {
  color: #888;
}
.rightMore {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media (min-width:768px){
  .accountLi {
  height: 38px;
}
  .infoLeft {
  border-right: 1px solid #ccc;
  padding: 0 50px;
}
.infoRight {
  padding: 0 50px;
}
.newsLi {
  margin-top: 20px;
  cursor: pointer;
  width: 48%;
  margin-right: 10px;
}
  .top-box{
    display: flex;
    justify-content: space-between;
  }
  .top-left-box {
  background: url("../../../assets/img/user/userbg.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 68%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px;
  height: 360px;
  border-radius: 5px;
}
.top-right-box {
  background: url("../../../assets/img/user/userbg.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 30%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 5px;
}
.bottom-box {
  background: url("../../../assets/img/user/userbg1.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 100%;
  margin: 20px 0;
  height: 310px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 5px;
}
}
@media (max-width:768px){
  .mobileLayout{
    display: block!important;
  }
  .mobile_userimg{
    text-align: center;
  }
  .accountLi {
 margin-top: 20px;
}
.newsLi{
  align-items: flex-start;
  margin-top: 20px;
}
.top-left-box {
  background: url("../../../assets/img/user/userbg.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 5px;
}
.top-right-box {
  background: url("../../../assets/img/user/userbg.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}
.middle-box{
  margin-top: 20px;
}
.bottom-box{
  margin-top: 20px;
}
}
</style>
