<template>
  <div class="">
    <div
      v-show="isRegister || isLogin || isUpdatePwd"
      class="black_overlay"
      @click="CloseDiv"
    ></div>
    <div v-show="isRegister" class="white_content pages">
      <div class="mobile_dia">
        <img
          class="dia-left"
          src="@/assets/img/login.png"
          v-show="deviceType == 'PC'"
        />
        <div class="dia-right">
          <div class="close" @click="CloseDiv">X</div>
          <div class="right-content">
            <div class="loginTitle">手机验证码注册</div>
            <div class="loginDesc">注册体验更多高效便捷服务</div>
            <div class="inputBox">
              <el-input
                class=""
                v-model="form.username"
                maxlength="11"
                placeholder="请输入您的手机号"
              ></el-input>
              <!-- <el-input
                class="_row"
                type="password"
                maxlength="13"
                v-model="form.password"
                placeholder="请输您的入密码"
              ></el-input>
              <el-input
                class="_row"
                type="password"
                maxlength="13"
                v-model="form.password1"
                placeholder="请再次输您的入密码"
              ></el-input> -->
              <el-input
                class="_row"
                maxlength="5"
                placeholder="请输入图形验证码"
                v-model="form.code"
              >
                <img
                  slot="suffix"
                  :src="form.img"
                  style="width: 100px; cursor: pointer; padding-top: 13px"
                  @click="changeRegistImg"
                />
              </el-input>
              <el-input
                class="_row"
                maxlength="5"
                placeholder="请输入短信验证码"
                v-model="form.messageCode"
              >
                <el-button
                  slot="suffix"
                  @click="sendVerifycode()"
                  :disabled="!disabledCodeBtn"
                  type="primary"
                  size="mini"
                  style="cursor: pointer; margin-top: 17px"
                >
                  {{ messageTxt }}
                </el-button>
              </el-input>
            </div>
            <div class="login" @click="registSubmit">注册</div>
          </div>
          <div class="right-desc">
            登录即视为同意用户服务协议、隐私政策和授权许可协议。
          </div>
        </div>
      </div>
    </div>
    <div v-show="isLogin" class="white_content pages">
      <div class="mobile_dia">
        <img
          class="dia-left"
          src="@/assets/img/login.png"
          v-show="deviceType == 'PC'"
        />
        <div class="dia-right">
          <div class="close" @click="CloseDiv">X</div>
          <div class="right-content">
            <div v-if="showLoginWay == 'wechat'">
              <!-- <div class="loginTitle">微信扫码登录</div>
              <div class="loginDesc">登录体验更多高效便捷服务</div> -->
              <div class="scanQrCode">
               <iframe  :src="wechatUrl" class="iframeImg"></iframe>
              <!-- <img class="scanQrCode" src="@/assets/img/qrcode.png" /> -->
            </div>
            </div>
            <div v-else-if="showLoginWay == 'phone'" style="height: 290px;">
              <div class="loginTitle">手机验证码登录</div>
              <div class="loginDesc">登录体验更多高效便捷服务</div>
              <div class="inputBox">
                <el-input
                  class="_row"
                  v-model="form.username"
                   maxlength="11"
                  placeholder="请输入您的手机号"
                ></el-input>
                <el-input
                  class="_row"
                  maxlength="5"
                  placeholder="请输入验证码"
                  v-model="form.code"
                >
                  <img
                    slot="suffix"
                    :src="form.img"
                    style="width: 100px; cursor: pointer; padding-top: 13px"
                    @click="changeImg"
                  />
                </el-input>
                <el-input
                class="_row"
                maxlength="5"
                placeholder="请输入短信验证码"
                v-model="form.messageCode"
              >
                <el-button
                  slot="suffix"
                  @click="sendVerifycode()"
                  :disabled="!disabledCodeBtn"
                  type="primary"
                  size="mini"
                  style="cursor: pointer; margin-top: 17px"
                >
                  {{ messageTxt }}
                </el-button>
              </el-input>
              </div>
              <div class="login" @click="loginVerifiSubmit">登录</div>
            </div>
            <div v-else>
              <div class="loginTitle">账号密码登录</div>
              <div class="loginDesc">登录体验更多高效便捷服务</div>
              <div class="inputBox">
                <el-input
                  class="_row"
                  v-model="form.username"
                   maxlength="11"
                  placeholder="请输入您的手机号"
                ></el-input>
                <el-input
                  class="_row"
                  type="password"
                  v-model="form.password"
                  placeholder="请输您的入密码"
                ></el-input>
                <el-input
                  class="_row"
                  maxlength="5"
                  placeholder="请输入验证码"
                  v-model="form.code"
                >
                  <img
                    slot="suffix"
                    :src="form.img"
                    style="width: 100px; cursor: pointer; padding-top: 13px"
                    @click="changeImg"
                  />
                </el-input>
              </div>
              <div class="login" @click="loginSubmit">登录</div>
            </div>
            <div class="_flex _justify_center cornor">
              <div class="cornerBox cornerLeft" @click="loginWay('wechat')">
                <img src="@/assets/img/login/wechat.png" class="cornorImg" />
                <div class="cornorTxt">微信扫码登录</div>
              </div>
              <div>
                <div class="cornerBox" @click="loginWay('account')"  v-if="showLoginWay=='phone'">
                <img src="@/assets/img/login/lock.png" class="cornorImg" />
                <div class="cornorTxt">账号密码登录</div>
              </div>
              <div class="cornerBox" @click="loginWay('phone')" v-else>
                <img src="@/assets/img/login/phone.png" class="cornorImg" />
                <div class="cornorTxt">手机验证码登录</div>
              </div>
              </div>
            </div>
          </div>
          <div class="right-desc">
            登录即视为同意用户服务协议、隐私政策和授权许可协议。
          </div>
        </div>
      </div>
    </div>
    <div v-show="isUpdatePwd" class="white_content pages">
      <div class="mobile_dia">
        <img
          class="dia-left"
          src="@/assets/img/login.png"
          v-show="deviceType == 'PC'"
        />
        <div class="dia-right">
          <div class="close" @click="CloseDiv">X</div>
          <div class="right-content">
            <div class="loginTitle">修改密码</div>
            <div class="loginDesc">体验更多高效便捷服务</div>
            <div class="inputBox">
              <el-input
                class="_row"
                type="password"
                v-if="hasContent"
                v-model="form.newPwd"
                placeholder="请输入您的原密码"
              ></el-input>
              <el-input
                class="_row"
                type="password"
                v-model="form.password"
                placeholder="请输您的新密码"
              ></el-input>
              <el-input
                class="_row"
                type="password"
                maxlength="13"
                v-model="form.password1"
                placeholder="请再次输您的入密码"
              ></el-input>
            </div>
            <div class="login" @click="submitPwd">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import { isDeviceType, isPhone } from "@/utils.js";
export default {
  name: "login",
  data() {
    return {
      isRegister: false,
      isLogin: false,
      isUpdatePwd: false,
      deviceType: "", //设备
      form: {
        username: "",
        password: "",
        password1: "",
        uuid: "",
        img: "",
      },
      hasContent:true,//是否可以输入原密码
      wechatUrl:"",
      sendUuid:"",
      showLoginWay: "phone",
      messageTxt: "发送验证码",
      disabledCodeBtn: true,
    };
  },
  created() {
    this.deviceType = isDeviceType();
  },
  methods: {
    changeImg() {
      this.getCode();
    },
    changeRegistImg(){
      this.getRegistCode()
    },
    // 获取注册图形验证码
    getRegistCode() {
      this.$ajax.get(URL.REGIST_CODE).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form.img = res.data.obj.img;
          this.form.uuid = res.data.obj.uuid;
        } else {
          this.$message.error(res.data.obj);
        }
      });
    },
    getCode() {
      this.$ajax.get(URL.VERIFICATION_CODE).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form.img = res.data.obj.img;
          this.form.uuid = res.data.obj.uuid;
        } else {
          this.$message.error(res.data.obj);
        }
      });
    },
    // 注册发送验证码
    sendVerifycode() {
      if (this.form.username) {
        if (!isPhone(this.form.username)) {
          this.$message.error("手机号格式不正确!");
          return;
        }
      } else {
        this.$message.error("手机号不能为空!");
        return;
      }
      if (!this.form.code) {
        this.$message.error("图形验证码不能为空!");
        return;
      }
      this.$ajax.post(URL.REGISTSENDSMS_CODE,{
          username: this.form.username,
          code:this.form.code,
          uuid: this.form.uuid,
        }).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
           this.sendUuid = res.data.obj.uuid
          this.countDown(60);
        } else {
          this.getRegistCode()
          this.$message.error(res.data.msg);
        }
      });
    },
     // 登录发送验证码
    sendLoginVerifycode() {
      if (this.form.username) {
        if (!isPhone(this.form.username)) {
          this.$message.error("手机号格式不正确!");
          return;
        }
      } else {
        this.$message.error("手机号不能为空!");
        return;
      }
      if (!this.form.code) {
        this.$message.error("图形验证码不能为空!");
        return;
      }
      this.$ajax.post(URL.SENDSMS_CODE,{
          username: this.form.username,
          code:this.form.code,
          uuid: this.form.uuid,
        }).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
           this.sendUuid = res.data.obj.uuid
          this.countDown(60);
        } else {
          this.getRegistCode()
          this.$message.error(res.data.msg);
        }
      });
    },
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.messageTxt = "获取验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.messageTxt = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
    // 注册
    registSubmit() {
      let that = this;
      if (this.form.username) {
        if (!isPhone(this.form.username)) {
          this.$message.error("手机号格式不正确!");
          return;
        }
      } else {
        this.$message.error("手机号不能为空!");
        return;
      }
      // if (!this.form.password) {
      //   this.$message.error("密码不能为空!");
      //   return;
      // }
      // if (!this.form.password1) {
      //   this.$message.error("再次输入密码不能为空!");
      //   return;
      // }
      // if (this.form.password != this.form.password1) {
      //   this.$message.error("两次输入密码不一致!");
      //   return;
      // }
      if (!this.form.messageCode) {
        this.$message.error("短信验证码不能为空!");
        return;
      }
      this.$ajax
        .post(URL.registryApi, {
          username: this.form.username,
          password: this.form.password,
          uuid: this.sendUuid,
          verification: this.form.messageCode,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            this.$confirm("注册成功，是否现在去登录?", "温馨提示", {
              confirmButtonText: "去登录",
              cancelButtonText: "取消",
              type: "success",
            })
              .then(() => {
                that.isRegister = false;
                that.isLogin = true;
                that.form.code = "";
                that.$forceUpdate();
                that.getCode();
              })
              .catch(() => {
                this.isRegister = false;
                this.move();
                window.location.replace("https://www.zhihuovip.com")
              });
          }
           else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 手机验证码登录
    loginVerifiSubmit() {
      let that = this;
      if (this.form.username) {
        if (!isPhone(this.form.username)) {
          this.$message.error("手机号格式不正确!");
          return;
        }
      } else {
        this.$message.error("手机号不能为空!");
        return;
      }
      if (!this.form.messageCode) {
        this.$message.error("短信验证码不能为空!");
        return;
      }
      this.$ajax
        .post(URL.MessageLogin, {
          username: this.form.username,
          uuid: this.sendUuid,
          verification: this.form.messageCode,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            let token = res.data.obj.token;
            let username = res.data.obj.user.user.username;
            this.move();
            sessionStorage.setItem("Authorization", token);
            sessionStorage.setItem("username", username);
            this.isLogin = false;
            this.$emit("refreshPage");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 登录
    loginSubmit() {
      if (this.form.username != "admin") {        //密码000000
        if (this.form.username) {
          if (!isPhone(this.form.username)) {
            this.$message.error("手机号格式不正确!");
            return;
          }
        } else {
          this.$message.error("手机号不能为空!");
          return;
        }
      }
      if (!this.form.password) {
        this.$message.error("密码不能为空!");
        return;
      }
      if (!this.form.code) {
        this.$message.error("验证码不能为空!");
        return;
      }
      this.$ajax
        .post(URL.loginApi, {
          username: this.form.username,
          password: this.form.password,
          uuid: this.form.uuid,
          code: this.form.code,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            let token = res.data.obj.token;
            let username = res.data.obj.user.user.username;
            this.move();
            sessionStorage.setItem("Authorization", token);
            sessionStorage.setItem("username", username);
            this.isLogin = false;
            this.$emit("refreshPage");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 修改密码
    submitPwd() {
      if (!this.form.newPwd&&this.hasContent) {
        this.$message.error("密码不能为空!");
        return;
      }
      if (!this.form.password) {
        this.$message.error("密码不能为空!");
        return;
      }
      if (!this.form.password1) {
        this.$message.error("再次输入密码不能为空!");
        return;
      }
      if (this.form.password != this.form.password1) {
        this.$message.error("两次输入密码不一致!");
        return;
      }
      this.$ajax
        .post(URL.updatePwdApi, {
          oldPwd: this.form.newPwd,
          newPwd: this.form.password,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            this.isUpdatePwd = false;
            this.$message({
              message: res.data.obj,
              type: "success",
            });
          }

           else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 登录方式切换
    loginWay(e) {
      console.log(e);
      this.showLoginWay = e;
      if(e=='wechat'){
        this.getWeChat()
      }
    },
    // 获取微信二维码
    getWeChat(){
      this.$ajax
        .get(URL.LOGIN_WECHATAPI)
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            this.wechatUrl = res.data.obj;
          } else {
            this.$message.error("服务器错误!");
          }
        })
        .catch((err) => {
        });
    },
    showRegist() {
      this.isRegister = true;
      this.getRegistCode()
      this.stop();
    },
    showLogin() {
      this.isLogin = true;
      this.getCode();
      this.stop();
    },
    showUpdatePwd() {
       this.$ajax
        .get(URL.CHECK_PWD)
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            this.hasContent = res.data.obj.hasContent;
           this.isUpdatePwd = true;
          } else {
            this.$message.error("服务器错误!");
          }
        })
        .catch((err) => {
        });
      this.stop();
    },
    CloseDiv() {
      this.isRegister = false;
      this.isLogin = false;
      this.isUpdatePwd = false;
      this.move();
    },
    //禁止滚动
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },

    //取消滑动限制
    move() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
  },
};
</script>

<style scoped>
.black_overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.8;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.dia-left {
  width: 50%;
  height: 500px;
}

.right-content {
  height: 410px;
}
.close {
  text-align: right;
  color: #888;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
}
.right-desc {
  background: #f3f3f3;
  color: #666;
  padding: 10px;
  font-size: 14px;
}
.inputBox {
  width: 306px;
  margin: 0 auto;
  padding-top: 10px;
}
._row {
  padding-top: 10px;
}
.loginTitle {
  font-size: 25px;
  text-align: center;
}
.loginDesc {
  color: #888;
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
}
.login {
  vertical-align: middle;
  background-image: linear-gradient(to right, #219dfe, #67dfd1);
  width: 266px;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  border-radius: 5px;
  margin: 20px auto;
}
@media (min-width: 768px) {
  .mobile_dia {
    display: flex;
  }
  .dia-right {
    width: 50%;
    height: 500px;
    background: #fbfbfb;
  }
  .white_content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 500px;
    background-color: white;
    z-index: 1002;
    overflow: hidden;
    border-radius: 5px;
  }
  .cornor {
    position: absolute;
    bottom: 44px;
        right: 22px;
  }
  .cornerBox {
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }
  .cornerLeft {
    border-right: 2px solid #eee;
  }
  .cornorImg {
    width: 40px;
    margin-right: 5px;
  }
  .cornorTxt {
    color: #999;
    font-size: 18px;
  }
  .scanQrCode {
    margin: 0 auto;
    width: 305px;
    height: 335px;
    display: block;
    overflow: hidden;
  }
  .iframeImg{
   width:100%;
   height: 405px;
   border: 0px;
  }
}
@media (max-width: 768px) {
  .dia-right {
    width: 100%;
    height: 450px;
    background: #fbfbfb;
  }
  .white_content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 15px;
    height: 450px;
    box-sizing: border-box;
    background-color: white;
    z-index: 1002;
    overflow: hidden;
    border-radius: 5px;
  }
  .cornor {
    margin-top: 60px;
  }
  .cornerBox {
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }
  .cornerLeft {
    border-right: 2px solid #eee;
  }
  .cornorImg {
    width: 40px;
    margin-right: 5px;
  }
  .cornorTxt {
    color: #999;
    font-size: 18px;
  }
  .scanQrCode {
    margin: 0 auto;
    width: 100%;
    height: 286px;
    display: block;
  }
  .iframeImg{
   width:100%;
   height: 315px;
   border: 0px;
  }
}
</style>
