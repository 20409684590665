/**
 * 匹配phone
 */
 export const isPhone = (str) => {
    const reg = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/
    return reg.test(str)
  }
  
// 判断是安卓还是ios
export function isDeviceType(){
	let deviceType;
	if (
        navigator.userAgent.match(/iPad/i) != null ||
        navigator.userAgent.match(
            /iphone|android|phone|mobile|wap|netfront|x11|java|opera mobi|opera mini|ucweb|windows ce|symbian|symbianos|series|webos|sony|blackberry|dopod|nokia|samsung|palmsource|xda|pieplus|meizu|midp|cldc|motorola|foma|docomo|up.browser|up.link|blazer|helio|hosin|huawei|novarra|coolpad|webos|techfaith|palmsource|alcatel|amoi|ktouch|nexian|ericsson|philips|sagem|wellcom|bunjalloo|maui|smartphone|iemobile|spice|bird|zte-|longcos|pantech|gionee|portalmmm|jig browser|hiptop|benq|haier|^lct|320x320|240x320|176x220/i
        ) != null
    ) {
        console.log("这是手机")
        deviceType = "mobile"
    } else {
        console.log("这是PC")
        deviceType = "PC"
    }
	return deviceType
}
 // 获取url参数
 export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let url = window.location.href.split("#")[0];
    let search = url.split("?")[1];
    if (search) {
      var r = search.substr(0).match(reg);
      if (r !== null) return unescape(r[2]);
      return null;
    } else {
      return null;
    }
  }