<template>
  <div class="">
    <headers :activeName="menuActive" @changeTab="changeTab"></headers>
    <div class="main">
      <!-- 导航 -->
      <div class="nav">
        <span>首页></span>
        <span>{{ initData.name }}</span>
      </div>
      <!-- 搜所栏 -->
      <hot-search @tabsChange="tabsChange"></hot-search>
      <!-- 新闻轮播 -->
      <div  class="_flex _justify_between">
        <el-carousel height="400px" style="width:100%" >
          <el-carousel-item v-for="(item,index) in form.swiper" :key="index">
            <div class="_flex">
              <el-image
              style="width: 60%; height: 400px"
              :src="item.imgUrl"
            ></el-image>
            <div class="newsPage">
                <div class="title2">
                  {{item.title}}
                </div>
                <div class="desc">{{item.description}}</div>
            </div>
            </div>
          </el-carousel-item>
        </el-carousel>   
      </div>
      <div class="news—top">
        <div class="newsBox">
          <div  class="newsTitle _flex _justify_between align-items_center">
            <div class="title1">{{form.newslist[0].category.name}}</div>
            <div class="more1" @click="moreClick(form.newslist[0])">更多></div>
          </div>
          <div>
            <div class="h5_newsLi " v-for="(item,index) in form.newslist[0].articles" @click="goDetail(item)">
              <div class="newsLi  _flex _justify_between align-items_center" >
                <div class="title2">{{item.title}}</div>
                <div class="time2">{{item.createTime}}</div>
              </div>
              <div class="desc">{{item.description}}</div>
            </div>
          </div>
        </div>
          <div class="newsBox1">
          <div  class="newsTitle _flex _justify_between align-items_center">
            <div class="title1">{{form.newslist[1].category.name}}</div>
            <div class="more1" @click="moreClick(form.newslist[1])">更多></div>
          </div>
          <div>
            <div class="h5_newsLi" v-for="(item,index) in form.newslist[1].articles" @click="goDetail(item)">
              <div class="newsLi  _flex _justify_between align-items_center" >
                <div class="title2">{{item.title}}</div>
                <div class="time2">{{item.createTime}}</div>
              </div>
              <div class="desc">{{item.description}}</div>
            </div>
          </div>
        </div>
      </div>
    <!-- 广告 -->
         <img
        class="_marginTop"
        v-if="banner_adv.length!=0"
        :src="banner_adv[0].imgUrl"
        style="width: 100%; border-radius: 10px"
      />
      <div  class="news—top">
        <div class="newsBox">
          <div  class="newsTitle _flex _justify_between align-items_center">
            <div class="title1">{{form.newslist[2].category.name}}</div>
            <div class="more1"  @click="moreClick(form.newslist[2])">更多></div>
          </div>
          <div   class="h5_newsLi">
            <div  v-for="(item,index) in form.newslist[2].articles" @click="goDetail(item)">
              <div class="newsLi1  _flex _justify_between align-items_center" >
                <div >{{item.title}}</div>
                <div class="time2">{{item.createTime}}</div>
              </div>
            </div>
          </div>
        </div>
          <div class="newsBox1">
          <div  class="newsTitle _flex _justify_between align-items_center">
            <div class="title1">{{form.newslist[3].category.name}}</div>
            <div class="more1" @click="moreClick(form.newslist[3])">更多></div>
          </div>
          <div  class="h5_newsLi">
            <div   v-for="(item,index) in form.newslist[3].articles" @click="goDetail(item)">
              <div class="newsLi1  _flex _justify_between align-items_center" >
                <div >{{item.title}}</div>
                <div class="time2">{{item.createTime}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers
      class="_marginTop"
      :activeName="menuActive"
      @changeTab="changeTab"
    ></footers>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import headers from "@/components/serveHead/serveHead";
import hotSearch from "@/components/hotSearch/hotSearch";
import footers from "@/components/pageBottom/pageBottom";
export default {
  components: {
    headers,
    hotSearch,
    footers,
  },
  data() {
    return {
      initData: "",
      menuActive: "",
      artical: "",
      banner_adv:[],//广告
      form: {
        swiper:[],
        newslist:[]
      },
    };
  },
  created() {
    this.getDetail();
    this.getAdvertisement()
  },
  methods: {
    getDetail() {
      this.initData = this.$route.query;
      console.log(this.initData);
      this.menuActive = this.initData.type;
       this.$ajax.get(URL.articleSwiper + "/1/10").then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form.swiper = res.data.obj;
        }
      });
       this.$ajax.get(URL.zixunArticle).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form.newslist = res.data.obj;
        }
      });
    }, 
     // tabs切换
    tabsChange(e){
      console.log(e,"外层")
      if(e!='0'){
         this.$refs.footers.appointmentClick();
      }
    },
    // 获取广告
    getAdvertisement() {
      let id='90';
      //获取广告
      this.$ajax.get(URL.findInfo_adv+'/'+id).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.banner_adv = res.data.obj;
          // console.log(this.adverList);
        }
      });
    },
    goDetail(e){
      console.log(e)
      this.$router.push({
        name:"service",
        query:{
          id:e.id
        }
      })
    },
    changeTab() {
      this.getDetail();
    },
      // 更多
      moreClick(e){
        console.log(e)
        this.$router.push({
          name:"knowledgeList",
          query:{
            id:e.category.id,
            title:e.category.name
          }
        })
      }
  },
};
</script>

<style scoped>
.newsPage{
  width: 40%;
  padding: 15px;
  height: 400px;
  background: #ECF5FF;
}
.newsPage .desc{
  color: #888;
  font-size: 14px;
  margin-top: 10px;
}

.newsTitle{
      padding: 20px 0;
    border-bottom: 1px solid #ccc;
}
.newsTitle .title1{
   font-weight:bold ; 
   padding-left: 5px;
   border-left:3px solid #116BFF;
}
.newsTitle .more1{
    color: #888;
    cursor: pointer;
}


 .title2{
  font-weight:bold ; 
      width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
 .time2{
  color: #888;
  font-size: 14px;
}
.desc{
  color: #888;
   cursor: pointer;
}
@media (min-width:768px){
  .news—top{
    display: flex;
    justify-content: space-between;
  }
  .newsBox{
  width: 58%;
}
.newsBox1{
  width: 38%;
}
.newsLi{
  margin-top: 20px;
      line-height: 40px;
      cursor: pointer;
}
.newsLi1{
  margin-top: 10px;
  cursor: pointer;
}
}
@media (max-width:768px){
  .h5_newsLi{
    padding: 0 15px;
    font-size: 14px;
  }
  .newsLi{
  margin-top: 20px;
      line-height: 20px;
      display: block;
      cursor: pointer;
}

.newsLi1{
  margin-top: 10px;
  display: block;
  cursor: pointer;
}
}
</style>
