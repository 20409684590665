<template>
  <div class="">
     <div class="topBox">
            <div>联系电话</div>
            <div class="topBox_desc">提交后您的专属顾问会联系您</div>
            <div class="infoBox">
              <el-input v-model="userform.phone" placeholder="*手机号" maxlength="11"></el-input>
              <el-input
                style="margin-top: 10px"
                v-model="userform.remark"
                placeholder="需求简述"
              ></el-input>
            </div>
            <div class="subbtn" @click="submitUser">提交</div>
          </div>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
export default {
  name: 'home',
  data(){
    return{
      userform:{
        phone:"",
        remark:""
      },//预约咨询
    }
  },
  methods:{
     // 预约咨询
    submitUser(){
      if(!this.userform.phone){
         this.$message.error('手机号不能为空!');
        return
      }
      this.$ajax.post(URL.reservationApi,{
        phone:this.userform.phone,
        content:this.userform.remark
      }).then((res) => {
        console.log(res)
         if (res.data.code == "0000") {
           this.$message({
              message: '提交成功',
              type: 'success'
            });
         }
      })
    },
  }
}
</script>

<style scoped>
.topBox {
  height: 200px;
  border-radius: 10px;
  background: #cfeefe;
  text-align: center;
  padding: 15px;
}
.topBox_desc {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}
.infoBox {
  margin-top: 20px;
}
.subbtn {
  vertical-align: middle;
  background-image: linear-gradient(to right, #219dfe, #67dfd1);
  width: 100%;
  height: 40px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  margin-top: 10px;
  border-radius: 7px;
  cursor: pointer;
}
</style>
