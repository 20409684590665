<template>
  <div class="">
    <!-- 头部 -->
    <div class="flot_head">
      <!-- 顶部 -->
      <div class="header _flex align-items_center _justify_between">
        <img src="@/assets/img/logo.png" class="webLogo" />
        <img
          src="@/assets/img/mobile/menu.png"
          class="webLogo"
          v-show="deviceType == 'mobile'"
          @click="mobileMenu"
        />
        <div class="_flex align-items_center" v-show="deviceType == 'PC'">
          <div
            class="_flex"
            style="margin-right: 40px; color: #1375ff; align-items: baseline"
          >
            <img
              src="@/assets/img/phone.png"
              style="height: 15px; margin-right: 5px"
            />
            <span>16601116787</span>
          </div>
          <div style="margin-right: 10px">
            <span style="cursor: pointer" @click="register" v-show="!islogin"
              >免费注册</span
            >
            <span style="cursor: pointer" @click="userManage" v-show="islogin"
              >管理控制中心</span
            >
          </div>
          <div style="cursor: pointer" @click="login" v-show="!islogin">
            登录
          </div>
        </div>
      </div>
      <!-- 导航栏 -->
      <!-- 导航栏 -->
      <div
        class="service_tabBox"
        v-show="deviceType == 'PC'"
        @mouseleave="mouseMenuLeave"
      >
        <div class="_flex">
          <div
            class="menuDefault"
            @mouseover="mouseMenuOver(pro)"
            :style="{
              'border-color':
                pro.code == menuActive ? '#116BFF' : 'transparent',
              color: pro.code == menuActive ? '#116BFF' : '#333',
            }"
            v-for="(pro, index1) in menuPCList"
            :key="index1"
            @click="handleClick(pro)"
          >
            {{ pro.name }}
          </div>
        </div>
        <div class="showMenuBox" v-show="showMenuAlert">
          <div class="showSubBox">
            <div
              class="_flex showSubLi"
              v-for="(item, index) in menuAlertData"
              :key="index"
            >
              <div class="showMenuTitle">{{ item.name }}</div>
              <div class="showMenuInfo">
                <span
                  v-for="(ite, idx) in item.children"
                  :key="idx"
                  @click="goDetail(ite, '')"
                  >{{ ite.name }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="index_tabBox"  v-show="deviceType=='PC'">
      <el-tabs v-model="menuActive" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in menuList"
          :label="item.name"
          :name="item.code"
        ></el-tab-pane>
      </el-tabs>
    </div> -->
    </div>
    <!--//// 头部 -->
    <div v-show="deviceType == 'PC'" style="height: 170px"></div>
    <!-- 轮播图 -->
    <el-carousel
      :height="deviceType == 'PC' ? '400px' : '200PX'"
      interval="6000"
    >
      <el-carousel-item v-for="(item, index) in swiperList" :key="index">
        <!-- <el-image
         
          
        ></el-image> -->
        <img :src="item.imgUrl" alt="" class="swiperImg" />
      </el-carousel-item>
    </el-carousel>
    <!-- 推荐菜单 -->
    <div class="introMenu _flex">
      <div class="_flex align-items_center introLi">
        <img src="@/assets/img/intromenu/01.png" />
        <div style="margin-left: 10px">
          <div>政策解读</div>
          <div style="color: #888; margin-top: 10px">实用政策，全面解读</div>
        </div>
      </div>
      <div class="_flex align-items_center introLi">
        <img src="@/assets/img/intromenu/02.png" />
        <div style="margin-left: 10px">
          <div>免费评估</div>
          <div style="color: #888; margin-top: 10px">科学测评，综合分析</div>
        </div>
      </div>
      <div class="_flex align-items_center introLi">
        <img src="@/assets/img/intromenu/03.png" />
        <div style="margin-left: 10px">
          <div>申报管理</div>
          <div style="color: #888; margin-top: 10px">项目申报，免费体验</div>
        </div>
      </div>
      <div class="_flex align-items_center introLi">
        <img src="@/assets/img/intromenu/04.png" />
        <div style="margin-left: 10px">
          <div>全程辅导</div>
          <div style="color: #888; margin-top: 10px">专业团队，负责到底</div>
        </div>
      </div>
    </div>
    <!-- 搜所栏 -->
    <hot-search @tabsChange="tabsChange"></hot-search>
    <!-- 子轮播 -->
    <div class="subSwiper">
      <el-carousel
        :height="deviceType == 'PC' ? '200px' : '90PX'"
        class="index_tabBox"
      >
        <el-carousel-item v-for="(item, index) in banner_adv.p1" :key="index">
          <img :src="item.imgUrl" class="adv_swiper" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 内容块 -->
    <div class="contentBox">
      <!-- 工商服务 -->
      <div class="lrBox">
        <div class="leftBg bg1">
          <div class="_flex _justify_between align-items_center titleBox">
            <div>{{ commodityList[0].category.name }}</div>
            <img
              src="@/assets/img/more.png"
              style="width: 30px; height: 7px; cursor: pointer"
              @click="handleClick({ label: '工商服务', name: 'gs' })"
            />
          </div>
          <div class="left_desc">
            {{ commodityList[0].category.explainContent }}
          </div>
          <div
            class="inzq _flex align-items_center"
            @click="handleClick({ label: '工商服务', name: 'gs' })"
          >
            <div>进入专区</div>
            <img
              src="@/assets/img/right.png"
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="rightBg">
          <div
            class="cardBox"
            v-for="(item, index) in commodityList[0].commodity"
            :key="index"
            @mouseover="cardmouseover(item)"
            @mouseleave="cardmouseleave(item)"
          >
            <div class="cardli_select" v-if="item.flag">
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-if="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice_select">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <span style="font-size: 12px"
                    >元起 / 原价{{ item.origialPrice }}元</span
                  >
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
              <div class="cardBottom _flex">
                <div
                  @click="appointmentClick()"
                  class="cardbtn1"
                  style="border-right: 1px solid #fff"
                >
                  <img
                    src="@/assets/img/time.png"
                    style="width: 16px; margin-right: 5px"
                  />
                  <span>预约</span>
                </div>
                <div class="cardbtn" @click="goDetail(item, 'gs')">
                  了解详情
                </div>
              </div>
            </div>
            <div class="cardli" v-else>
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-show="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 财税服务 -->
      <div class="lrBox _marginTop">
        <div class="leftBg bg2">
          <div class="_flex _justify_between align-items_center titleBox">
            <div>{{ commodityList[1].category.name }}</div>
            <img
              src="@/assets/img/more.png"
              style="width: 30px; height: 7px; cursor: pointer"
              @click="handleClick({ label: '财税服务', name: 'cs' })"
            />
          </div>
          <div class="left_desc">
            {{ commodityList[1].category.explainContent }}
          </div>
          <div
            class="inzq _flex align-items_center"
            @click="handleClick({ label: '财税服务', name: 'cs' })"
          >
            <div>进入专区</div>
            <img
              src="@/assets/img/right.png"
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="rightBg">
          <div
            class="cardBox"
            v-for="(item, index) in commodityList[1].commodity"
            :key="index"
            @mouseover="cardmouseover(item)"
            @mouseleave="cardmouseleave(item)"
          >
            <div class="cardli_select" v-if="item.flag">
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-if="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice_select">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <span style="font-size: 12px"
                    >元起 / 原价{{ item.origialPrice }}元</span
                  >
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
              <div class="cardBottom _flex">
                <div
                  @click="appointmentClick()"
                  class="cardbtn1"
                  style="border-right: 1px solid #fff"
                >
                  <img
                    src="@/assets/img/time.png"
                    style="width: 16px; margin-right: 5px"
                  />
                  <span>预约</span>
                </div>
                <div class="cardbtn" @click="goDetail(item, 'cs')">
                  了解详情
                </div>
              </div>
            </div>
            <div class="cardli" v-else>
              <img class="cardNew" src="@/assets/img/new.png" />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 广告图 -->
      <img
        class="_marginTop"
        v-if="banner_adv.p2.length != 0"
        :src="banner_adv.p2[0].imgUrl"
        style="width: 100%; border-radius: 10px"
      />
      <!-- 专利服务 -->
      <div class="lrBox _marginTop">
        <div class="leftBg bg3">
          <div class="_flex _justify_between align-items_center titleBox">
            <div>{{ commodityList[2].category.name }}</div>
            <img
              src="@/assets/img/more.png"
              style="width: 30px; height: 7px; cursor: pointer"
              @click="handleClick({ label: '专利服务', name: 'zl' })"
            />
          </div>
          <div class="left_desc">
            {{ commodityList[1].category.explainContent }}
          </div>
          <div
            class="inzq _flex align-items_center"
            @click="handleClick({ label: '专利服务', name: 'zl' })"
          >
            <div>进入专区</div>
            <img
              src="@/assets/img/right.png"
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="rightBg">
          <div
            class="cardBox"
            v-for="(item, index) in commodityList[2].commodity"
            :key="index"
            @mouseover="cardmouseover(item)"
            @mouseleave="cardmouseleave(item)"
          >
            <div class="cardli_select" v-if="item.flag">
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-if="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice_select">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <span style="font-size: 12px"
                    >元起 / 原价{{ item.origialPrice }}元</span
                  >
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
              <div class="cardBottom _flex">
                <div
                  @click="appointmentClick()"
                  class="cardbtn1"
                  style="border-right: 1px solid #fff"
                >
                  <img
                    src="@/assets/img/time.png"
                    style="width: 16px; margin-right: 5px"
                  />
                  <span>预约</span>
                </div>
                <div class="cardbtn" @click="goDetail(item, 'zl')">
                  了解详情
                </div>
              </div>
            </div>
            <div class="cardli" v-else>
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-if="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 商标版权 -->
      <div class="lrBox _marginTop">
        <div class="leftBg bg4">
          <div class="_flex _justify_between align-items_center titleBox">
            <div>{{ commodityList[3].category.name }}</div>
            <img
              src="@/assets/img/more.png"
              style="width: 30px; height: 7px; cursor: pointer"
              @click="handleClick({ label: '商标版权', name: 'sb' })"
            />
          </div>
          <div class="left_desc">
            {{ commodityList[3].category.explainContent }}
          </div>
          <div
            class="inzq _flex align-items_center"
            @click="handleClick({ label: '商标版权', name: 'sb' })"
          >
            <div>进入专区</div>
            <img
              src="@/assets/img/right.png"
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="rightBg">
          <div
            class="cardBox"
            v-for="(item, index) in commodityList[3].commodity"
            :key="index"
            @mouseover="cardmouseover(item)"
            @mouseleave="cardmouseleave(item)"
          >
            <div v-if="!item.long">
              <div class="cardli_select" v-if="item.flag">
                <img
                  class="cardNew"
                  src="@/assets/img/new.png"
                  v-show="item.tags.indexOf('最新') != -1"
                />
                <div style="padding: 15px">
                  <div class="card_title">{{ item.name }}</div>
                  <div class="cardDesc">
                    {{ item.brief }}
                  </div>
                  <div class="cardPrice_select">
                   <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <span style="font-size: 12px"
                    >元起 / 原价{{ item.origialPrice }}元</span
                  >
                  </div>
                  <div v-else>价格面议</div>
                  </div>
                </div>
                <div class="cardBottom _flex">
                  <div
                    @click="appointmentClick()"
                    class="cardbtn1"
                    style="border-right: 1px solid #fff"
                  >
                    <img
                      src="@/assets/img/time.png"
                      style="width: 16px; margin-right: 5px"
                    />
                    <span>预约</span>
                  </div>
                  <div class="cardbtn" @click="goDetail(item, 'sb')">
                    了解详情
                  </div>
                </div>
              </div>
              <div class="cardli" v-else>
                <img
                  class="cardNew"
                  src="@/assets/img/new.png"
                  v-show="item.tags.indexOf('最新') != -1"
                />
                <div style="padding: 15px">
                  <div class="card_title">{{ item.name }}</div>
                  <div class="cardDesc">
                    {{ item.brief }}
                  </div>
                  <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cardBox" style="width: 64%">
            <div class="cardlilong" style="padding: 15px">
              <div class="_flex _justify_between">
                <div class="card_title">爆款商标</div>
              </div>
              <div class="_flex">
                <div
                  class="long_img"
                  @click="goDetail(ite, 'sb')"
                  v-for="(ite, index) in commodityList[3].spec"
                >
                  <div style="margin-top: 5px">
                    <img
                      v-if="ite.imgUrl"
                      :src="ite.imgUrl"
                      style="width: 40px; height: 40px"
                    />
                    <img
                      v-else
                      src="@/assets/img/imgEmpty.jpg"
                      style="width: 40px; height: 40px"
                    />
                  </div>
                  <div>
                    <span style="color: #ff0000">{{ ite.price }}</span>
                    <span style="color: #888">元</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 广告图 -->
      <img
        class="_marginTop"
        v-if="banner_adv.p3.length != 0"
        :src="banner_adv.p3[0].imgUrl"
        style="width: 100%; border-radius: 10px"
      />
      <!-- 企业认证 -->
      <div class="lrBox _marginTop">
        <div class="leftBg bg5">
          <div class="_flex _justify_between align-items_center titleBox">
            <div>{{ commodityList[4].category.name }}</div>
            <img
              src="@/assets/img/more.png"
              style="width: 30px; height: 7px; cursor: pointer"
              @click="handleClick({ label: '企业认证', name: 'qy' })"
            />
          </div>
          <div class="left_desc">
            {{ commodityList[4].category.explainContent }}
          </div>
          <div
            class="inzq _flex align-items_center"
            @click="handleClick({ label: '企业认证', name: 'qy' })"
          >
            <div>进入专区</div>
            <img
              src="@/assets/img/right.png"
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="rightBg">
          <div
            class="cardBox"
            v-for="(item, index) in commodityList[4].commodity"
            :key="index"
            @mouseover="cardmouseover(item)"
            @mouseleave="cardmouseleave(item)"
          >
            <div class="cardli_select" v-if="item.flag">
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-show="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice_select">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <span style="font-size: 12px"
                    >元起 / 原价{{ item.origialPrice }}元</span
                  >
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
              <div class="cardBottom _flex">
                <div
                  @click="appointmentClick()"
                  class="cardbtn1"
                  style="border-right: 1px solid #fff"
                >
                  <img
                    src="@/assets/img/time.png"
                    style="width: 16px; margin-right: 5px"
                  />
                  <span>预约</span>
                </div>
                <div class="cardbtn" @click="goDetail(item, 'qy')">
                  了解详情
                </div>
              </div>
            </div>
            <div class="cardli" v-else>
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-show="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 资质项目 -->
      <div class="lrBox _marginTop">
        <div class="leftBg bg6">
          <div class="_flex _justify_between align-items_center titleBox">
            <div>{{ commodityList[5].category.name }}</div>
            <img
              src="@/assets/img/more.png"
              style="width: 30px; height: 7px; cursor: pointer"
              @click="handleClick({ label: '资质项目', name: 'zz' })"
            />
          </div>
          <div class="left_desc">
            {{ commodityList[5].category.explainContent }}
          </div>
          <div
            class="inzq _flex align-items_center"
            @click="handleClick({ label: '资质项目', name: 'zz' })"
          >
            <div>进入专区</div>
            <img
              src="@/assets/img/right.png"
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="rightBg">
          <div
            class="cardBox"
            v-for="(item, index) in commodityList[5].commodity"
            :key="index"
            @mouseover="cardmouseover(item)"
            @mouseleave="cardmouseleave(item)"
          >
            <div v-if="!item.long">
              <div class="cardli_select" v-if="item.flag">
                <img
                  class="cardNew"
                  src="@/assets/img/new.png"
                  v-show="item.tags.indexOf('最新') != -1"
                />
                <div style="padding: 15px">
                  <div class="card_title">{{ item.name }}</div>
                  <div class="cardDesc">
                    {{ item.brief }}
                  </div>
                  <div class="cardPrice_select">
                    <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <span style="font-size: 12px"
                    >元起 / 原价{{ item.origialPrice }}元</span
                  >
                  </div>
                  <div v-else>价格面议</div>
                  </div>
                </div>
                <div class="cardBottom _flex">
                  <div
                    @click="appointmentClick()"
                    class="cardbtn1"
                    style="border-right: 1px solid #fff"
                  >
                    <img
                      src="@/assets/img/time.png"
                      style="width: 16px; margin-right: 5px"
                    />
                    <span>预约</span>
                  </div>
                  <div class="cardbtn" @click="goDetail(item, 'zz')">
                    了解详情
                  </div>
                </div>
              </div>
              <div class="cardli" v-else>
                <img
                  class="cardNew"
                  src="@/assets/img/new.png"
                  v-show="item.tags.indexOf('最新') != -1"
                />
                <div style="padding: 15px">
                  <div class="card_title">{{ item.name }}</div>
                  <div class="cardDesc">
                    {{ item.brief }}
                  </div>
                  <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
                </div>
              </div>
            </div>
            <div class="cardlilong" v-else style="padding: 15px">
              <div style="display: inline-block; vertical-align: middle">
                <div>
                  <div>国家高新企业</div>
                </div>
                <div class="cardDesc">
                  极速申报快至一分钟，多样化服务 省时省力
                </div>
                <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
              <div class="detailBtn" @click="goDetail(item)">了解详情</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 广告图 -->
      <img
        class="_marginTop"
        v-if="banner_adv.p4.length != 0"
        :src="banner_adv.p4[0].imgUrl"
        style="width: 100%; border-radius: 10px"
      />
      <!-- 技术服务 -->
      <div class="lrBox _marginTop">
        <div class="leftBg bg7">
          <div class="_flex _justify_between align-items_center titleBox">
            <div>{{ commodityList[6].category.name }}</div>
            <img
              src="@/assets/img/more.png"
              style="width: 30px; height: 7px; cursor: pointer"
              @click="handleClick({ label: '技术服务', name: 'js' })"
            />
          </div>
          <div class="left_desc">
            {{ commodityList[6].category.explainContent }}
          </div>
          <div
            class="inzq _flex align-items_center"
            @click="handleClick({ label: '技术服务', name: 'js' })"
          >
            <div>进入专区</div>
            <img
              src="@/assets/img/right.png"
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="rightBg">
          <div
            class="cardBox"
            v-for="(item, index) in commodityList[6].commodity"
            :key="index"
            @mouseover="cardmouseover(item)"
            @mouseleave="cardmouseleave(item)"
          >
            <div class="cardli_select" v-if="item.flag">
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-show="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice_select">
                 <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <span style="font-size: 12px"
                    >元起 / 原价{{ item.origialPrice }}元</span
                  >
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
              <div class="cardBottom _flex">
                <div
                  @click="appointmentClick()"
                  class="cardbtn1"
                  style="border-right: 1px solid #fff"
                >
                  <img
                    src="@/assets/img/time.png"
                    style="width: 16px; margin-right: 5px"
                  />
                  <span>预约</span>
                </div>
                <div class="cardbtn" @click="goDetail(item, 'js')">
                  了解详情
                </div>
              </div>
            </div>
            <div class="cardli" v-else>
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-show="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 法律服务 -->
      <div class="lrBox _marginTop">
        <div class="leftBg bg8">
          <div class="_flex _justify_between align-items_center titleBox">
            <div>{{ commodityList[7].category.name }}</div>
            <img
              src="@/assets/img/more.png"
              style="width: 30px; height: 7px; cursor: pointer"
              @click="handleClick({ label: '法律服务', name: 'fl' })"
            />
          </div>
          <div class="left_desc">
            {{ commodityList[7].category.explainContent }}
          </div>
          <div
            class="inzq _flex align-items_center"
            @click="handleClick({ label: '法律服务', name: 'fl' })"
          >
            <div>进入专区</div>
            <img
              src="@/assets/img/right.png"
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </div>
        </div>
        <div class="rightBg">
          <div
            class="cardBox"
            v-for="(item, index) in commodityList[7].commodity"
            :key="index"
            @mouseover="cardmouseover(item)"
            @mouseleave="cardmouseleave(item)"
          >
            <div class="cardli_select" v-if="item.flag">
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-show="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice_select">
                 <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <span style="font-size: 12px"
                    >元起 / 原价{{ item.origialPrice }}元</span
                  >
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
              <div class="cardBottom _flex">
                <div
                  @click="appointmentClick()"
                  class="cardbtn1"
                  style="border-right: 1px solid #fff"
                >
                  <img
                    src="@/assets/img/time.png"
                    style="width: 16px; margin-right: 5px"
                  />
                  <span>预约</span>
                </div>
                <div class="cardbtn" @click="goDetail(item, 'fl')">
                  了解详情
                </div>
              </div>
            </div>
            <div class="cardli" v-else>
              <img
                class="cardNew"
                src="@/assets/img/new.png"
                v-show="item.tags.indexOf('最新') != -1"
              />
              <div style="padding: 15px">
                <div class="card_title">{{ item.name }}</div>
                <div class="cardDesc">
                  {{ item.brief }}
                </div>
                <div class="cardPrice">
                  <div v-if="item.price!='0'">
                    <span>{{ item.price }}</span>
                  <div style="display: inline-block">
                    <span style="font-size: 12px; color: #888"
                      >元起 / 原价{{ item.origialPrice }}元</span
                    >
                    <img
                      v-show="item.tags.indexOf('惊爆价') != -1"
                      src="@/assets/img/hot.png"
                      style="
                        width: 26px;
                        margin-left: 5px;
                        vertical-align: middle;
                      "
                    />
                  </div>
                  </div>
                  <div v-else>价格面议</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 广告图 -->
      <img
        class="_marginTop"
        v-if="banner_adv.p5.length != 0"
        :src="banner_adv.p5[0].imgUrl"
        style="width: 100%; border-radius: 10px"
      />
    </div>
    <!-- 咨询三栏 -->
    <div class="zixunMain _marginTop">
      <div class="index_tabBox _flex zixun _justify_between">
        <div
          class="zixunli"
          v-for="(item, index) in bottomArticle"
          :key="index"
        >
          <div style="font-weight: bold; margin-bottom: 10px">
            {{ item.category.name }}
          </div>
          <img
            :src="item.articles[0].imgUrl"
            style="width: 100%; height: 150px"
          />
          <div class="zixun_title">{{ item.category.description }}</div>
          <div class="zixun_content">
            <div
              style="cursor: pointer"
              v-for="(ite, idx) in item.articles"
              :key="idx"
              @click="goNewsDetail(ite)"
            >
              {{ ite.title }}
            </div>
          </div>
          <div class="moreBtn" @click="moreClick(item)">了解更多资讯</div>
        </div>
      </div>
    </div>
    <div class="teamwork">
      <div class="_marginTop" style="font-weight: bold; font-size: 30px">
        合作企业
      </div>
      <div class="_flex _wrap _marginTop">
        <div v-html="brandContent"></div>
        <!-- <div
          class="adver"
        >
          <img src="@/assets/img/brand.png" style="width: 100%" />
        </div> -->
      </div>
    </div>
    <footers ref="footers" :activeName="menuActive"></footers>
    <login ref="login" @refreshPage="refreshPage"></login>
    <menu-aside ref="mobileMenu" v-show="deviceType == 'mobile'"></menu-aside>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import { isDeviceType } from "@/utils.js";
import login from "@/components/login/login";
import { getUrlParam } from "@/utils.js";
import menuAside from "@/components/mobile/menuAside";
import hotSearch from "@/components/hotSearch/hotSearch";
import footers from "@/components/pageBottom/pageBottom";
export default {
  components: {
    login,
    menuAside,
    hotSearch,
    footers,
  },
  name: "home",
  data() {
    return {
      cities: [
        {
          value: 1,
          label: "天津",
        },
        {
          value: 2,
          label: "北京",
        },
      ],
      deviceType: "", //设备
      menuActive: "Home",
      islogin: false, //是否登录
      appointment: false, //预约
      showMenuAlert: false, //菜单下拉框
      menuPCList: [], //PC动态菜单栏
      menuAlertData: [], //菜单浮动框子数据
      menuList: [
        {
          name: "首页",
          code: "Home",
        },
        {
          name: "工商服务",
          code: "gs",
        },
        {
          name: "财税服务",
          code: "cs",
        },
        {
          name: "专利服务",
          code: "zl",
        },
        {
          name: "商标版权",
          code: "sb",
        },
        {
          name: "企业认证",
          code: "qy",
        },
        {
          name: "资质项目",
          code: "zz",
        },
        {
          name: "技术服务",
          code: "js",
        },
        {
          name: "法律服务",
          code: "fl",
        },
        {
          name: "资讯知识库",
          code: "zxzs",
        },
        {
          name: "关于我们",
          code: "aboutUs",
        },
      ],
      searchActive: "serve",
      searchMenuList: [
        {
          name: "查服务",
          code: "serve",
        },
        {
          name: "免费核名",
          code: "hm",
        },
        {
          name: "免费起名",
          code: "qm",
        },
      ],
      searchValue: "",
      hotList: [
        {
          name: "商标注册",
        },
        {
          name: "公司注册",
        },
        {
          name: "专利申请",
        },
      ],
      swiperList: [],
      adverList: [],
      commodityList: [],
      banner_adv: {
        //广告
        p1: [],
        p2: [],
        p3: [],
        p4: [],
        p5: [],
      },
      bottomArticle: [], //热点资讯
      brandContent: "", //合作企业富文本
    };
  },
  created() {
    this.deviceType = isDeviceType();
    this.getSwiper();
    this.getAdvertisement();
    this.commodityApi();
    this.refreshPage();
    this.bottomArticles();
    this.getBrandApi();
    this.getNavList();
    if (getUrlParam("code")) {
      let code = getUrlParam("code");
      let state = getUrlParam("state");
      this.loginWechat(code, state);
    }
  },
  methods: {
    // 刷新
    refreshPage() {
      if (sessionStorage.getItem("Authorization")) {
        this.islogin = true;
      }
    },
    // 获取导航路由
    getNavList() {
      this.$ajax
        .get(URL.NAV_MENU, {
          name: this.searchName,
          pageNum: this.page,
          pageSize: this.size,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            this.menuPCList = res.data.obj;
          } else {
            this.$message.error("服务器错误!");
          }
        })
        .catch((err) => {});
    },
    // 导航栏移入
    mouseMenuOver(e) {
      console.log(e);
      this.menuAlertData = e.children;
      if (this.menuAlertData.length == 0) {
        this.showMenuAlert = false;
      } else {
        this.showMenuAlert = true;
      }
    },
    // 导航栏移出
    mouseMenuLeave(e) {
      this.showMenuAlert = false;
    },
    // 登录
    login() {
      this.$refs.login.showLogin();
    },
    // 注册
    register() {
      this.$refs.login.showRegist();
    },
    // 获取轮播图
    getSwiper() {
      //获取个人网站信息
      this.$ajax.get(URL.HOME_SWIGER).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.swiperList = res.data.obj;
          console.log(this.swiperList);
        }
      });
    },
    // tabs切换
    tabsChange(e) {
      console.log(e, "外层");
      if (e != "0") {
        this.$refs.footers.appointmentClick();
      }
    },
    // 预约咨询
    appointmentClick() {
      this.$refs.footers.appointmentClick();
    },
    // 绑定微信
    loginWechat(code, state) {
      this.$ajax
        .post(URL.LOGIN_WECHAT, {
          code: code,
          state: state,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            let binded = res.data.obj.binded;
            if (binded) {
              let token = res.data.obj.token;
              let username = res.data.obj.user.user.username;
              sessionStorage.setItem("Authorization", token);
              sessionStorage.setItem("username", username);
              this.refreshPage();
              this.$message({
                message: "登录成功",
                type: "success",
              });
              setTimeout(() => {
                window.location.href = "https://www.zhihuovip.com/userManage";
              }, 500);
            } else {
              this.$alert("请先注册后绑定微信", "温馨提示", {
                confirmButtonText: "确定",
              });
              this.$refs.login.showRegist();
            }
          } else {
            this.$message.error("服务器错误!");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 热点资讯
    bottomArticles() {
      //获取个人网站信息
      this.$ajax.get(URL.bottomArticles).then((res) => {
        console.log(res.data.obj);
        if (res.data.code == "0000") {
          this.bottomArticle = res.data.obj;
        }
      });
    },
    // 获取广告
    getAdvertisement() {
      //获取个人网站信息
      this.$ajax.get(URL.HOME_ADVERTIS).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.banner_adv = res.data.obj;
          // console.log(this.adverList);
        }
      });
    },

    // 合作企业
    getBrandApi() {
      //获取个人网站信息
      this.$ajax.get(URL.brandApi + "/15").then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.brandContent = res.data.obj.content;
          // console.log(this.adverList);
        }
      });
    },
    // 获取商品
    commodityApi() {
      //获取个人网站信息
      this.$ajax.get(URL.commodityApi).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.commodityList = res.data.obj;
          console.log(this.commodityList);
        }
      });
    },
    // 详情
    goDetail(e, type) {
      console.log(e);
      this.$router.push({
        name: "companyRegister",
        query: {
          id: e.id,
          name: e.name,
          type: type,
        },
      });
    },
    // 资讯详情
    goNewsDetail(e) {
      console.log(e);
      this.$router.push({
        name: "service",
        query: {
          id: e.id,
        },
      });
    },
    // 更多
    moreClick(e) {
      console.log(e);
      this.$router.push({
        name: "knowledgeList",
        query: {
          id: e.category.id,
          title: e.category.name,
        },
      });
    },
    // 手机端菜单
    mobileMenu() {
      this.$refs.mobileMenu.showMenu();
    },
    // 跳转用户中心
    userManage() {
      this.$router.push({
        name: "userManage",
      });
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        name: e.code,
        query: {
          name: e.name,
          type: e.code,
        },
      });
    },
    // 鼠标移入
    cardmouseover(e) {
      e.flag = true;
    },
    // 鼠标移出
    cardmouseleave(e) {
      e.flag = false;
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .flot_head {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 666;
    background: #fff;
  }
  .header {
    width: 1200px;
    height: 80px;
    margin: 0 auto;
  }
  .webLogo {
    height: 50px;
  }
  .index_tabBox {
    width: 1200px;
    margin: 0 auto;
  }
  .introMenu {
    width: 1200px;
    margin: 0 auto;
  }
  .introLi {
    width: 25%;
    margin-top: 50px;
  }
  .swiperImg {
    width: 100%;
    height: 400px;
  }
  .adv_swiper {
    width: 100%;
    height: 200px;
  }
  .subSwiper {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 20px;
    overflow: hidden;
  }
  .contentBox {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .leftBg {
    width: 20%;
    height: 460px;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
  }
  .inzq {
    position: absolute;
    bottom: 50px;
    font-size: 20px;
    left: 50%;
    color: #fff;
    cursor: pointer;
    transform: translateX(-50%);
  }
  .titleBox {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
  }
  .left_desc {
    margin-top: 30px;
    color: #fff;
  }
  .rightBg {
    width: 80%;
    height: 458px;
    border: 1px solid #cccccc;
    background: #f6f6f6;
    border-radius: 0px 15px 15px 0;
  }
  .lrBox {
    display: flex;
  }
  .lrBox .bg1 {
    background: url("../../assets/img/bg/m1.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
  }
  .lrBox .bg2 {
    background: url("../../assets/img/bg/m2.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
  }
  .lrBox .bg3 {
    background: url("../../assets/img/bg/m3.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
  }
  .lrBox .bg4 {
    background: url("../../assets/img/bg/m4.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
  }
  .lrBox .bg5 {
    background: url("../../assets/img/bg/m5.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
  }
  .lrBox .bg6 {
    background: url("../../assets/img/bg/m6.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
  }
  .lrBox .bg7 {
    background: url("../../assets/img/bg/m7.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
  }
  .lrBox .bg8 {
    background: url("../../assets/img/bg/m8.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
  }
  .cardBox {
    width: 32%;
    display: inline-block;
    cursor: pointer;
  }
  .cardli {
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    height: 122px;
    margin: 20px 10px 0 10px;
    box-sizing: border-box;
    position: relative;
  }
  .cardli_select {
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    margin: -30px 10px 0 10px;
    box-sizing: border-box;
    border: 1px solid #116bff;
    position: relative;
  }
  .cardlilong {
    background: #fff;
    border-radius: 15px;
    height: 122px;
    overflow: hidden;
    margin: 20px 10px 0 10px;
    box-sizing: border-box;
  }
  .long_brand {
    color: #888;
    font-size: 14px;
  }
  .long_brand span {
    margin: 0 5px;
    cursor: pointer;
  }
  .long_img {
    margin-right: 6%;
    text-align: center;
  }
  .cardNew {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 35px;
  }
  .cardBottom {
    background: #116bff;
    color: #fff;
  }
  .cardBottom .cardbtn1 {
    width: 50%;
    text-align: center;
    font-size: 14px;
    height: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .cardBottom .cardbtn {
    width: 50%;
    text-align: center;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }
  .cardDesc {
    margin: 10px 0;
    font-size: 12px;
    color: #888;
    height: 38px;
    line-height: 21px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .cardPrice {
    margin-top: 5px;
    color: #ff0000;
  }
  .cardPrice_select {
    margin-top: 5px;
    color: #116bff;
  }
  .detailBtn {
    display: inline-block;
    vertical-align: middle;
    background-image: linear-gradient(to right, #219dfe, #67dfd1);
    width: 160px;
    height: 50px;
    text-align: center;
    color: #fff;
    line-height: 50px;
    border-radius: 7px;
    margin-left: 20px;
  }
  .zixunMain {
    background: url("../../assets/img/bg/bg1.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    width: 100%;
    padding: 20px 0;
  }
  .zixun {
    height: 396px;
  }
  .zixunli {
    width: 30%;
    background: #fff;
    border-radius: 6px;
    padding: 15px;
  }
  .zixun_title {
    color: #333;
    text-align: center;
  }
  .zixun_content {
    color: #888;
    font-size: 14px;
    line-height: 25px;
    height: 128px;
    margin-top: 5px;
  }
  .moreBtn {
    font-size: 14px;
    background: #116bff;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    margin: 8px auto;
    width: 150px;
    cursor: pointer;
  }
  .teamwork {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  .adver {
    width: 16.5%;
    height: 168px;
    padding: 20px;
    box-sizing: border-box;
  }
  /* 导航栏 */
  .service_tabBox {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    height: 60px;
    padding: 10px 0px 0 30px;
  }
  .menuDefault {
    padding-bottom: 10px;
    cursor: pointer;
    color: #333;
    margin: 10px 37px 0 0;
    font-size: 15px;
    transition: all 0.5s ease;
    border-bottom: 2px solid #116bff;
  }
  .menuDefault:hover {
    font-weight: bold;
  }
  .showMenuBox {
    position: absolute;
    width: 1200px;
    min-height: 200px;
    background: #fff;
    left: 50%;
    top: 60px;
    z-index: 111;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);
  }
  .showMenuBox {
    padding: 20px;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
  .showMenuTitle {
    color: #333;
    width: 110px;
    font-weight: bold;
  }
  .showSubLi {
    padding-bottom: 20px;
  }
  .showMenuInfo {
    width: 1000px;
    color: #555;
    line-height: 30px;
  }
  .showMenuInfo span {
    margin-right: 25px;
    font-size: 15px;
    cursor: pointer;
  }
  .showMenuInfo span:hover {
    color: #409eff;
  }
  /* 导航栏 */
}
@media (max-width: 768px) {
  .header {
    width: 100%;
    height: 80px;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .webLogo {
    height: 30px;
  }
  .introMenu {
    flex-wrap: wrap;
    padding: 15px;
    font-size: 14px;
  }
  .swiperImg {
    width: 100%;
    height: 200px;
  }
  .introLi {
    width: 46%;
    margin: 0 10px 10px 0;
  }
  .introLi img {
    width: 70px;
    height: 70px;
  }
  .adv_swiper {
    width: 100%;
    height: 90px;
  }
  .lrBox {
    margin-top: 10px;
    color: #fff;
  }
  .lrBox .bg1 {
    background: url("../../assets/img/bg/m1.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    padding: 15px;
  }
  .lrBox .bg2 {
    background: url("../../assets/img/bg/m2.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    padding: 15px;
  }
  .lrBox .bg3 {
    background: url("../../assets/img/bg/m3.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    padding: 15px;
  }
  .lrBox .bg4 {
    background: url("../../assets/img/bg/m4.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    padding: 15px;
  }
  .lrBox .bg5 {
    background: url("../../assets/img/bg/m5.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    padding: 15px;
  }
  .lrBox .bg6 {
    background: url("../../assets/img/bg/m6.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    padding: 15px;
  }
  .lrBox .bg7 {
    background: url("../../assets/img/bg/m7.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    padding: 15px;
  }
  .lrBox .bg8 {
    background: url("../../assets/img/bg/m8.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    padding: 15px;
  }
  .cardBox {
    width: 50%;
    display: inline-block;
    cursor: pointer;
  }
  .cardli {
    border: 2px solid #eee;
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    height: 150px;
    margin: 20px 10px 0 10px;
    box-sizing: border-box;
    position: relative;
  }
  .cardPrice_select {
    margin-top: 5px;
    color: #116bff;
  }
  .cardlilong {
    background: #fff;
    border-radius: 15px;
    height: 122px;
    overflow: hidden;
    margin: 20px 10px 0 10px;
    box-sizing: border-box;
    border: 2px solid #eee;
  }
  .cardli_select {
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    margin: -30px 10px 0 10px;
    box-sizing: border-box;
    border: 1px solid #116bff;
    position: relative;
  }
  .long_brand {
    color: #888;
    font-size: 14px;
  }
  .long_brand span {
    margin: 0 5px;
    cursor: pointer;
  }
  .long_img {
    margin-right: 6%;
    text-align: center;
  }
  .cardNew {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 35px;
  }
  .card_title {
    color: #333;
  }
  .cardBottom {
    background: #116bff;
    color: #fff;
  }
  .cardBottom .cardbtn1 {
    width: 50%;
    text-align: center;
    font-size: 14px;
    height: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .cardBottom .cardbtn {
    width: 50%;
    text-align: center;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }
  .cardDesc {
    margin: 10px 0;
    font-size: 12px;
    color: #888;
    height: 38px;
    line-height: 21px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .cardPrice {
    margin-top: 5px;
    color: #ff0000;
  }
  .zixunMain {
    background: url("../../assets/img/bg/bg1.png") no-repeat;
    background-size: 100% 100%;
    background-position: 0;
    width: 100%;
    padding: 20px 0;
  }
  .zixun {
    display: block !important;
  }
  .zixunli {
    width: 100%;
    background: #fff;
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
  }
  .zixun_title {
    color: #333;
    text-align: center;
  }
  .zixun_content {
    color: #888;
    font-size: 14px;
    line-height: 25px;
    height: 128px;
    margin-top: 5px;
  }
}
</style>
