import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import ajax from 'axios'
import '@/assets/common.scss'
import '@/assets/el.css'
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css';
import { Toast,Loading  } from 'vant'
Vue.use(Toast).use(Loading)
Vue.use(ElementUI);
Vue.config.productionTip = false
  
  ajax.interceptors.request.use(
    config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
      let token = sessionStorage.getItem('Authorization')
      if (!config.headers.hasOwnProperty('Authorization') && token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    });
    Vue.prototype.$ajax = ajax
    Vue.prototype.$ajax.interceptors.response.use(
      res => {
        console.log(res.data,"全局")
        if( res.data.code==500){
          ElementUI.Message({
            title: '警告',
            message: '服务器错误,请联系管理员',
            type: 'error'
        });
         }
       
        return res
      },
      error => {
       if( error.response.status!=200){
        ElementUI.Message({
          title: '警告',
          message: '服务器错误,请联系管理员',
          type: 'error'
      });
       }
      })
router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  //路由
 /* if (to.path === '/mainparts') {
    next();
  } else {
    let token = sessionStorage.getItem('token');
    if (!token) {
      next('/mainparts');
    } else {
      next();
    }
  }*/
  next();
})
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
