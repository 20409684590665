 <template>
  <div  v-loading="loading">
    <div class="searchWidth">
      <el-input prefix-icon="el-icon-search"   @keyup.enter.native="searchAllCompany" v-model="searchName" placeholder="查询服务名称"></el-input>
    </div>
    <div class="_marginTop" style="height:500px">
      <el-table :data="form.rows" style="width: 100%">
      <el-table-column prop="orderNumber" label="订单编号" width="180">
      </el-table-column>
       <el-table-column prop="serviceName" label="服务名称"> </el-table-column>
      <el-table-column prop="remark" label="服务内容"> </el-table-column>
       <el-table-column prop="createTime" label="生成日期" width="180">
      </el-table-column>
       <el-table-column prop="money" width="180" label="服务金额（元）">
      </el-table-column>
       <el-table-column  label="服务进度" width="180">
         <template slot-scope="scope">
           <div>
             {{scope.row.status}}
           </div>
         </template>
      </el-table-column>
    </el-table>
    </div>
  <div  v-if="form.rows.length!=0" style="    text-align: center;">
      <el-pagination background layout="prev, pager, next" :total="form.total"> </el-pagination>
  </div>
  </div>
</template>

  <script>
import { URL, BASEURL } from "@/urlpath.js";
export default {
  data() {
    return {
      form:{
          rows:[]
      },
        loading:false,
        searchName:"",
      page:1,
       size:12
    };
  },
    created() {
    this.getDetail();
  },
  methods: {
     getDetail() {
       this.loading = true;
       this.$ajax.post(URL.USER_APPOINTMENT,{
          name:this.searchName,
         pageNum:this.page,
        pageSize:this.size,
       }).then((res) => {
        console.log(res);
         this.loading = false;
        if (res.data.code == "0000") {
          this.form = res.data.obj;
        }else{
            this.$message.error('服务器错误!');
        }
      }).catch(err=>{
          this.loading = false;
        });
    },
    // 搜所
    searchAllCompany(){
      this.getDetail()
    },
      // 分页
    pageChange(e){
      console.log(e)
      this.page = e;
    },
  },
};
</script>

<style scoped>
@media (min-width:768px){
  .searchWidth{
    width:500px;
  }
}
@media (max-width:768px){

}
</style>
