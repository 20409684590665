<template>
  <div class="">
    <div class="footBox" v-show="deviceType == 'PC'">
      <div class="footMain">
        <el-tabs
          style="padding: 20px 0 0 20px"
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            v-for="(item, index) in menuList"
            :label="item.name"
            :name="item.code"
          ></el-tab-pane>
        </el-tabs>
        <div class="_flex middleBox">
          <div class="_flex foot_nav foot-left">
            <div style="margin-right: 50px">
              <div
                @click="goDetail(item)"
                class="bottomNav"
                v-for="(item, index) in bottomObj.commonuse"
                :key="index"
              >
                {{ item.title }}
              </div>
            </div>
            <div>
              <div
                @click="goDetail(item)"
                class="bottomNav"
                v-for="(item, index) in bottomObj.aboutus"
                :key="index"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="_flex foot-middle">
            <div class="qr_box" style="margin-right: 50px">
              <div class="qr_img_box">
                <img
                  src="@/assets/img/gzhWeChat.jpg"
                  style="width: 100px; height: 100px"
                />
              </div>
              <div>知火公众号</div>
            </div>
            <div class="qr_box">
              <div class="qr_img_box">
                <img
                  src="@/assets/img/weixin.jpg"
                  style="width: 100px; height: 100px"
                />
              </div>
              <div>知火客服号</div>
            </div>
          </div>
          <div class="foot-right">
            <div class="_flex align-items_center">
              <img
                src="@/assets/img/footer-tel.png"
                style="width: 23px; height: 23px; margin-right: 10px"
              />
              <div>
                <div style="font-size: 30px">16601116787</div>
                <div class="workTime">工作时间：9:00~18:00</div>
              </div>
              <div class="lyBtn" @click="appointmentClick()">留言</div>
            </div>
            <div class="_flex align-items_center _marginTop">
              <img
                src="@/assets/img/footer-mail.png"
                style="width: 23px; height: 18px; margin-right: 10px"
              />
              <div>
                <div>
                  市场合作：service@zhihuovip.com
                  <span style="color: #888">（仅限产品、流量、友链合作）</span>
                </div>
                <div style="margin-top: 10px">
                  商务合作：service@zhihuovip.com
                  <span style="color: #888"
                    >（渠道合作、线下合作、平台合作）</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 20px 0 0 20px; color: #afafb3">
          热门业务：
          <span
            style="cursor: pointer; margin-right: 10px"
            @click="hotSearchs(item)"
            v-for="(item, index) in hotSearch"
            :key="index"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
    <div v-show="deviceType == 'mobile'">
      <div class="footBox">
        <div class="_flex align-items_center">
          <img
            src="@/assets/img/footer-tel.png"
            style="width: 23px; height: 23px; margin-right: 10px"
          />
          <div>
            <div style="font-size: 20px; white-space: nowrap">16601116787</div>
            <div class="workTime">工作时间：9:00~18:00</div>
          </div>
          <div class="lyBtn" @click="appointmentClick()">留言</div>
        </div>
        <div class="_flex align-items_center _marginTop">
          <img
            src="@/assets/img/footer-mail.png"
            style="width: 23px; height: 18px; margin-right: 10px"
          />
          <div>
            <div>
              市场合作：service@zhihuovip.com
              <span>（仅限产品、流量、友链合作）</span>
            </div>
            <div style="margin-top: 10px">
              商务合作：service@zhihuovip.com
              <span>（渠道合作、线下合作、平台合作）</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom——bg">
      © 2021-2026 zhihuovip.com 版权所有
      ICP备案证书号：京字B2-20210132　京ICP备1404382
      <a style="color: #5a5959; margin-left: 10px" href="https://www.wzjs888.com" target="_blank"
        >技术支持 ： 匠人匠心科技</a>
    </div>
    <el-dialog
      title="联系电话"
      :visible.sync="appointment"
      class="contactAlert"
      :width="deviceType == 'mobile' ? '100%' : '22%'"
    >
      <div class="topBox_desc">提交后您的专属顾问会联系您</div>
      <el-form ref="form" :model="userform" label-width="10px">
        <el-form-item label="">
          <el-input
            v-model="userform.phone"
            placeholder="请您输入手机号"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            type="textarea"
            :rows="5"
            v-model="userform.remark"
            placeholder="请您输入需求简述"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="appointment = false">取 消</el-button> -->
        <div class="subbtn" @click="submitUser">提交</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import { isDeviceType } from "@/utils.js";
export default {
  name: "home",
  props: {
    activeName: {
      type: String,
      default: "Home",
    },
  },
  data() {
    return {
      bottomObj: {
        aboutus: [],
        commonuse: [],
      },
      deviceType: "", //设备
      menuList: [
        {
          name: "首页",
          code: "Home",
        },
        {
          name: "工商服务",
          code: "gs",
        },
        {
          name: "财税服务",
          code: "cs",
        },
        {
          name: "专利服务",
          code: "zl",
        },
        {
          name: "商标版权",
          code: "sb",
        },
        {
          name: "企业认证",
          code: "qy",
        },
        {
          name: "资质项目",
          code: "zz",
        },
        {
          name: "技术服务",
          code: "js",
        },
        {
          name: "法律服务",
          code: "fl",
        },
        {
          name: "资讯知识库",
          code: "zxzs",
        },
        {
          name: "关于我们",
          code: "aboutUs",
        },
      ],
      userform: {
        phone: "",
        remark: "",
      }, //预约咨询
      deviceType: "", //设备
      appointment: false, //预约
      hotSearch: [
        {
          name: "公司注册",
        },
        {
          name: "代理记账",
        },
        {
          name: "发明专利",
        },
        {
          name: "商标注册",
        },
        {
          name: "AAA企业信用评级",
        },
        {
          name: "国家高新企业认定",
        },
        {
          name: "软件测试",
        },
        {
          name: "法律顾问",
        },
      ],
    };
  },
  created() {
    this.deviceType = isDeviceType();
    this.getWebsiteInfo();
  },
  methods: {
    // 网站信息
    getWebsiteInfo() {
      //获取个人网站信息
      this.$ajax.get(URL.websiteInfo).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.bottomObj = res.data.obj;
          console.log(this.adverList);
        }
      });
    },
    // 预约咨询
    appointmentClick() {
      this.appointment = true;
    },
    hotSearchs(e) {
      this.$router.push({
        name: "searchList",
        query: {
          name: e.name,
        },
      });
    },
    // 预约咨询
    submitUser() {
      if (!this.userform.phone) {
        this.$message.error("手机号不能为空!");
        return;
      }
      if (!this.userform.remark) {
        this.$message.error("需求简述不能为空!");
        return;
      }
      this.$ajax
        .post(URL.reservationApi, {
          phone: this.userform.phone,
          content: this.userform.remark,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == "0000") {
            this.appointment = false;
            this.$message({
              message: "提交成功",
              type: "success",
            });
          }
        });
    },
    goDetail(e) {
      this.$router.push({
        name: "aboutUs",
        query: {
          id: e.id,
        },
      });
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        name: e.name,
        query: {
          name: e.label,
          type: e.name,
        },
      });
      this.$emit("changeTab");
    },
  },
};
</script>

<style scoped>
.footBox {
  background: #29325f;
  width: 100%;
  height: 450px;
  padding: 15px;
  box-sizing: border-box;
  color: #fff;
}
.footMain {
  width: 1200px;
  height: 80px;
  margin: 0 auto;
}
.foot_nav {
  color: #fff;
  line-height: 36px;
}
.qr_box {
  text-align: center;
  color: #fff;
}
.qr_img_box {
  padding: 10px;
  background: #fff;
}
.foot-left {
  width: 25%;
}
.foot-middle {
  width: 25%;
  margin-top: 30px;
}
.foot-right {
  width: 50%;
  margin-top: 30px;
  padding-left: 30px;
  color: #fff;
}

.lyBtn {
  background-image: linear-gradient(to right, #219dfe, #67dfd1);
  width: 160px;
  height: 50px;
  text-align: center;
  color: #fff;
  line-height: 50px;
  border-radius: 7px;
  margin-left: 50px;
  cursor: pointer;
}
.middleBox {
  padding: 20px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-top: 10px;
}

.bottomNav {
  cursor: pointer;
}
.topBox_desc {
  font-size: 14px;
  color: #888;
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .workTime {
    color: #888;
    margin-top: 5px;
  }
  .bottom——bg {
    width: 100%;
    background: #000;
    height: 60px;
    color: #5a5959;
    line-height: 60px;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .footBox {
    background: #29325f;
    width: 100%;
    color: #fff;
    font-size: 14px;
    height: 200px;
  }
  .workTime {
    white-space: nowrap;
    margin-top: 5px;
  }
  .lyBtn {
    background-image: linear-gradient(to right, #219dfe, #67dfd1);
    width: 160px;
    height: 50px;
    text-align: center;
    color: #fff;
    line-height: 50px;
    border-radius: 7px;
    margin-left: 50px;
    cursor: pointer;
  }
  .bottom——bg {
    font-size: 14px;
    background: #000;
    color: #5a5959;
    padding: 15px;
    text-align: center;
  }
}
.subbtn {
  vertical-align: middle;
  background-image: linear-gradient(to right, #219dfe, #67dfd1);
  width: 80%;
  height: 40px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  margin: 0 auto;
  border-radius: 7px;
  cursor: pointer;
}
</style>
