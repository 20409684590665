<template>
  <div class="bottomBox">
    <div>
              <img
                src="@/assets/img/linkserver.png"
                style="width: 15px; margin-right: 5px"
              />
              <div style="display: inline-block">最新服务</div>
            </div>
            <div class="bottom_box_main" v-for="(item, index) in form" @click="goDetail(item,'')">
              <div>{{item.name}}</div>
              <div style="margin-top: 5px">
                <div  v-if="item.price!='0'">
                  <span style="color: #ff0000">￥{{item.price}}</span>
                <span style="font-size: 12px; color: #888">起</span>
                </div>
                <div v-else> 
                    <span style="color: #ff0000">价格面议</span>
                </div>
              </div>
              <div class="bottomBox_desc">
                {{item.brief}}
              </div>
            </div>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
export default {
  name: 'home',
  data(){
    return{
      form:{

      }
    }
  },
   created(){
    this.getDetail()
  },
  methods: {
     // 详情
    goDetail(e,type) {
      console.log(e)
      this.$router.push({
        name: "companyRegister",
        query: {
          id:e.id,
          name:e.name,
          type:type
        },
      });
    },
    getDetail(){
       this.$ajax.get(URL.newZixunDetail).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
         this.form = res.data.obj
         console.log(this.form)
        }
      });
    }

  }
}
</script>

<style scoped>
.bottomBox{
  background: #ecf5ff;
  margin-top: 20px;
   padding: 15px;
       border-radius: 10px;
}
.bottomBox .bottom_box_main {
  padding: 15px 0;
  cursor: pointer;
  border-bottom: 1px solid #999;
}
.bottomBox .bottomBox_desc {
  font-size: 12px;
  color: #888;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
  white-space: nowrap;
}

</style>
