<template>
  <div class="">
    <el-container>
      <el-header>
        <div class="_flex _justify_between align-items_center headBox">
          <img src="@/assets/img/cms/logo-usercenter.png" style="height: 30px" />
          <img src="@/assets/img/mobile/whiteMenu.png"  class="webLogo"  v-show="deviceType=='mobile'" @click="mobileMenu"/>
          <div class="_flex  align-items_center " v-show="deviceType=='PC'">
            <div class="head_corner" @click="goHome"  >
              <img src="@/assets/img/cms/home.png" style="height: 20px" />
              <span>首页</span>
            </div>
             <div class="head_corner"  v-show="deviceType=='PC'">
              <img src="@/assets/img/phone_white.png" style="height: 20px" />
              <span>16601116787</span>
            </div>
              <el-dropdown>
             <div class="head_corner"  v-show="deviceType=='PC'">
              <img v-if="form.userPhoto" :src="form.userPhoto" style="width:30px;height: 30px;    border-radius: 50%;" />
              <img v-if="!form.userPhoto" src="@/assets/img/cms/admin-img.png" style="height: 30px" />
              <span style="color:#fff">{{username}}</span>
               <img class="el-dropdown-link"  src="@/assets/img/cms/DropDownArrow.png" style="height: 10px;margin-left: 5px" />
            </div>
              <el-dropdown-menu slot="dropdown" >
                <el-dropdown-item @click.native="changePwd">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
            </el-dropdown>
             
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside width="200px"  v-show="deviceType=='PC'">
          <div  class="_flex  align-items_center menuLi " :style="{'background': index==active?'#fff':'#F3F3F3'}" v-for="(item,index) in sideList" @click="menuClick(item,index)">
           <img v-if="item.type" :src="item.icon" style="width: 23px;height: 23px;margin-right:5px" />
           <img v-if="!item.type" :src="item.icon" style="width: 15px;height: 15px;margin-right:5px" />
            <div :style="{'color':item.type?'#2170E9':'#333'}">{{item.name}}</div>
          </div>
        </el-aside>
        <el-main>
          <div class="user-title">{{title}}</div>
          <div class="_marginTop mains" >
            <user-center @more="more" v-if="title=='用户中心'"></user-center>
            <personInfo @upDateImg="getDetail"  v-if="title=='个人资料'"></personInfo>
            <appointment v-else-if="title=='我的订单'"></appointment>
            <service  v-else-if="title=='服务记录'"></service>
            <collect  v-else-if="title=='我的收藏'"></collect>
            <history v-else-if="title=='浏览历史'"></history>
            <message v-else-if="title=='留言反馈'"></message>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <login ref="login"  @refreshPage="refreshPage"></login>
     <menu-aside ref="mobileMenu" @menuClick="menuClick" @logout="logout" :username="username" v-show="deviceType=='mobile'"></menu-aside>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import { isDeviceType } from "@/utils.js";
import login from "@/components/login/login";
import menuAside from "@/components/mobile/userAside";
import userCenter from "@/views/userManage/component/userCenter";
import service from "@/views/userManage/component/service";
import personInfo from "@/views/userManage/component/personInfo";
import collect from "@/views/userManage/component/collect";
import appointment from "@/views/userManage/component/appointment";
import history from "@/views/userManage/component/history";
import message from "@/views/userManage/component/message";
export default {
  components: {
    login,
    menuAside:menuAside,
    userCenter: userCenter,
    personInfo: personInfo,
    collect: collect,
    service: service,
    appointment: appointment,
    history: history,
    message: message,
  },
  name: "home",
  data() {
    return {
      title: "用户中心",
      active: 0,
      form: {},
      deviceType: "", //设备
      sideList: [
        {
          name: "用户中心",
          icon: require("@/assets/img/user/user-0.png"),
          type: true,
        },
        {
          name: "个人资料",
          icon: require("@/assets/img/user/user-center-ico-1.png"),
        },
        {
          name: "我的订单",
          icon: require("@/assets/img/user/user-center-ico-3.png"),
        },
        // {
        //   name: "服务记录",
        //   icon: require("@/assets/img/user/user-center-ico-5.png"),
        // },
        {
          name: "我的收藏",
          icon: require("@/assets/img/user/user-center-ico-2.png"),
        },
        {
          name: "浏览历史",
          icon: require("@/assets/img/user/user-center-ico-4.png"),
        },
        {
          name: "留言反馈",
          icon: require("@/assets/img/user/user-center-ico-6.png"),
        },
      ],
      sideHeight: 0,
      boxHeight: 0,
      username: "",
    };
  },
  created() {
    this.deviceType = isDeviceType();
    this.sideHeight = window.screen.height - 203 + "px";
    this.boxHeight = window.screen.height - 335 + "px";
    this.getDetail();
    console.log(window.screen.height);
    console.log(this.sideHeight);
  },
  methods: {
    getDetail() {
      this.username = sessionStorage.getItem("username");
      this.$ajax.get(URL.USER_INFO).then((res) => {
        if (res.data.code == "0000") {
          let obj = res.data.obj;
          this.form.userPhoto = obj.userPhoto;
          this.$forceUpdate();
        } else {
          this.$message.error("服务器错误!");
          setTimeout(()=>{
            this.$router.push({
              path:'/'
            })
          },800)
        }
      });
    },
     // 手机端菜单
      mobileMenu(){
        this.$refs.mobileMenu.showMenu()
      },
    // 刷新
    refreshPage() {},
    // 用户中心更多跳转
    more(e) {
      console.log(e);
      this.title = e.title;
      this.active = e.active;
    },
    goHome() {
      this.$router.push({
        name: "Home",
      });
    },
    menuClick(e, index) {
      console.log(e)
      this.title = e.name;
      this.active = index;
    },
    // 修改密码
    changePwd() {
      this.$refs.login.showUpdatePwd();
    },
    logout() {
      this.$ajax.delete(URL.logoutApi).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.$message({
            message: "退出成功",
            type: "success",
          });
          sessionStorage.clear();
          this.$router.replace({ name: "Home" });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-header {
  background-color: #003895;
  color: #fff;
}
.headBox {
  height: 60px;
}
.head_corner {
  margin-right: 35px;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.head_corner img {
  margin-right: 5px;
}
.el-aside {
  background-color: #f3f3f3;
  color: #333;
  position: fixed;
  z-index: 5;
  height: 100%;
}
.mains {
  overflow-y: scroll;
}
.mains::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.mains::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #e2e1e1;
}

.mains::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(247, 245, 245, 0.2);
  border-radius: 10px;
  background: #fff;
}
.menuLi {
  height: 50px;
  padding-left: 20px;
  cursor: pointer;
}

.user-title {
  color: #116bff;
  padding-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid #cccccc;
}
@media (min-width:768px){
  .el-main {
  position: fixed;
  z-index: 1;
  height: 100%;
  padding-left: 250px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
  min-width: 1600px;
}
}
@media (max-width:768px){
  .webLogo{
  height: 30px;
  padding: 0 15px;
}
}
</style>
