<template>
  <div class="">
    <headers :activeName="menuActive" @changeTab="changeTab"></headers>
    <div v-loading="loading">
      <div class="main">
        <!-- 导航 -->
        <div class="nav">
          <span>首页></span>
          <span>{{ initData.name }}</span>
        </div>
        <!-- 搜所栏 -->
        <hot-search @tabsChange="tabsChange"></hot-search>
        <!-- 工商介绍 -->
        <div class="_marginTop" style="text-align: center">
          <div style="font-weight: bold; font-size: 20px">
            {{ initData.name }}
          </div>
          <div style="margin-top: 10px; color: #888">
            {{ form.categoryInfo.explainContent }}
          </div>
          <div
            style="
              width: 50px;
              height: 1px;
              background: #116bff;
              margin: 20px auto;
            "
          ></div>
          <div
            style="
              color: #888;
              font-size: 14px;
              padding: 0 20px;
              line-height: 28px;
            "
          >
            {{ form.categoryInfo.description }}
          </div>
        </div>
        <!-- 创业起步套餐模块 -->
        <div class="mobile_tc">
        <div class="mobile_tcBox _marginTop _flex _justify_between">
          <div
            class="tcBox"
            v-for="(item, index) in form.threeCommoditys"
            :key="index"
          >
            <div class="tc_title">{{ item.name }}</div>
            <div class="tc_desc">{{ item.brief }}</div>
            <div class="tc_main">
              <div
                class="_flex align-items_center _justify_center"
                v-for="(ite, idx) in item.introduce.split(';')"
                :key="idx"
              >
                <img src="@/assets/img/correct.png" style="width: 15px" />
                <span>{{ ite }}</span>
              </div>
            </div>
            <div class="_marginTop">
              <div v-if="item.price!='0'">
                <span style="color: #ff0000; font-size: 18px">{{
                item.price
              }}</span>
              <span style="color: #888; font-size: 14px"
                >元起/原价{{ item.origialPrice }}元</span
              >
              </div>
              <div v-else>
                <span style="color: #ff0000; font-size: 18px"
                >价格面议</span
              >
              </div>
            </div>
            <div class="detailBtn" @click="goDetail(item, initData.type)">
              查看详情
            </div>
          </div>
        </div>
        </div>
        <!-- 广告 -->
         <img
        class="_marginTop"
        v-if="banner_adv.length!=0"
        :src="banner_adv[0].imgUrl"
        style="width: 100%; border-radius: 10px"
      />
        <!-- 服务板块 -->
        <div>
          <div
            class="_marginTop"
            v-for="(item, index) in form.commoditInfo"
            :key="index"
          >
            <div>{{ item.category.name }}</div>
            <div class="_flex _wrap">
              <div
                class="serve_card"
                v-for="(ite, idx) in item.commoditys"
                :key="idx"
                @click="goDetail(ite, initData.type)"
              >
                <div class="blueBlock"></div>
                <div class="serve_title">{{ ite.name }}</div>
                <div class="serve_desc">{{ ite.brief }}</div>
                <div>
                 <div v-if="ite.price!='0'">
                    <span style="color: #ff0000; font-size: 18px">{{
                    ite.price
                  }}</span>
                  <span style="color: #888; font-size: 14px; margin-left: 5px"
                    >元起</span
                  >
                 </div>
                 <div v-else>
                    <span style="color: #ff0000; font-size: 18px">价格面议</span>
                 </div>
                </div>
              </div>
            </div>
          </div>
          <div class="zx-box" >
            <div class="zx—btn"  @click="appointmentClick()">没有想要的服务？咨询更多服务内容</div>
          </div>
        </div>
        <!-- 服务模块流程 -->
        <div class="_marginTop" v-if="form.topContent">
          <div class="question-title">{{ form.topContent.name }}</div>
          <div class="question-desc">
            {{ form.topContent.explainContent }}
          </div>
          <div style="margin: 15px" v-html="form.topContent.content"></div>
        </div>
      </div>

      <!-- 办理材料 -->
      <div class="banliMain _marginTop" v-if="form.middleContent">
        <div class="banli_box">
          <div class="question-title">{{ form.middleContent.name }}</div>
          <div class="question-desc">
            {{ form.middleContent.explainContent }}
          </div>
          <div class="_flex banli_menu">
            <div
              v-for="(item, index) in form.middleContent.children"
              :key="index"
              :class="[banliActive == index ? 'banli_li_select' : 'banli_li']"
              @click="banliChange(item, index)"
            >
              {{ item.smallTitle }}
            </div>
          </div>
          <div v-html="banliContent"></div>
          <!-- <div class="_flex _justify_center _marginTop">
            <img
              src="@/assets/img/pro-img-2.png"
              style="height: 300px; margin-right: 50px"
            />
            <div class="banli_right">
              <div class="_flex align-items_center" style="margin-top: 0px">
                <img
                  src="@/assets/img/correct.png"
                  style="width: 20px; height: 20px; margin-right: 10px"
                />
                <div>
                  <div>核名</div>
                  <div class="banli_remark">2-5个名称字号</div>
                </div>
              </div>
              <div class="_flex align-items_center">
                <img
                  src="@/assets/img/correct.png"
                  style="width: 20px; height: 20px; margin-right: 10px"
                />
                <div>
                  <div>核名</div>
                  <div class="banli_remark">2-5个名称字号</div>
                </div>
              </div>
              <div class="_flex align-items_center">
                <img
                  src="@/assets/img/correct.png"
                  style="width: 20px; height: 20px; margin-right: 10px"
                />
                <div>
                  <div>核名</div>
                  <div class="banli_remark">2-5个名称字号</div>
                </div>
              </div>
              <div class="_flex align-items_center">
                <img
                  src="@/assets/img/correct.png"
                  style="width: 20px; height: 20px; margin-right: 10px"
                />
                <div>
                  <div>核名</div>
                  <div class="banli_remark">2-5个名称字号</div>
                </div>
              </div>
              <div class="_flex align-items_center">
                <img
                  src="@/assets/img/correct.png"
                  style="width: 20px; height: 20px; margin-right: 10px"
                />
                <div>
                  <div>核名</div>
                  <div class="banli_remark">2-5个名称字号</div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="zx-box">
            <div class="zx—btn"  @click="appointmentClick()">没有注册地址？立即咨询专业顾问解决</div>
          </div>
        </div>
      </div>
      <!-- 常见问题 -->
      <div class="question" v-if="form.buttomContent">
        <div class="question-title">{{ form.buttomContent.name }}</div>
        <div class="question-desc">
          {{ form.buttomContent.explainContent }}
        </div>
        <div v-html="form.buttomContent.content"></div>
        <div class="zx-box">
          <div class="zx—btn"  @click="appointmentClick()">没有想要的服务？咨询更多服务内容</div>
        </div>
        <!-- <div v-html="artical"></div> -->
      </div>
    </div>
    <footers
    ref="footers"
      class="_marginTop"
      :activeName="menuActive"
      @changeTab="changeTab"
    ></footers>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import headers from "@/components/serveHead/serveHead";
import hotSearch from "@/components/hotSearch/hotSearch";
import footers from "@/components/pageBottom/pageBottom";
export default {
  components: {
    headers,
    hotSearch,
    footers,
  },
  data() {
    return {
      loading: false,
      initData: {
        type: "",
        name: "",
      },
      menuActive: "",
      artical: "",
      form: {
        buttomContent: {
          explainContent: "",
          name: "",
        },
        categoryInfo: {
          description: "",
          explainContent: "",
          name: "",
        },
        commoditInfo: [],
        middleContent: { children: [], explainContent: "", name: "" },
        threeCommoditys: [],
        topContent: {
          explainContent: "",
          name: "",
        },
      },
      searchActive: "serve",
      searchMenuList: [
        {
          name: "查服务",
          code: "serve",
        },
        {
          name: "免费核名",
          code: "hm",
        },
        {
          name: "免费起名",
          code: "qm",
        },
      ],
      hotList: [
        {
          name: "商标注册",
        },
        {
          name: "公司注册",
        },
        {
          name: "专利申请",
        },
      ],
      // 工商服务
      serveList: [
        {
          sonList: [{}, {}, {}, {}, {}, {}],
        },
        {
          sonList: [{}, {}, {}, {}, {}, {}],
        },
      ],
      // 办理材料
      banliActive: 0,
      banliContent:"",
      banli_menu: [
        {
          name: "注册公司您需要准备材料",
        },
        {
          name: "注册成功后您将得到",
        },
        {
          name: "公司正式开展经营还需",
        },
      ],
      banner_adv:[]
    };
  },
    watch: {
    $route(to, from) {
      // 对路由变化作出响应...
      console.log(" 对路由变化作出响应...");
      this.getDetail();
      this.getAdvertisement()
    },
  },
  created() {
    this.getDetail();
    this.getAdvertisement()
  },
  methods: {
    getDetail() {
      this.initData = this.$route.query;
      console.log(this.initData);
      this.menuActive = this.initData.type;
      this.loading = true;
      this.$ajax
        .post(URL.commodityDetail, {
          name: this.initData.name,
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.code == "0000") {
            this.form = res.data.obj;
            this.banliContent = res.data.obj.middleContent.children[0].content;
            this.$forceUpdate();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
// 获取广告
    getAdvertisement() {
      let id;
      console.log(this.initData.type)
      if(this.initData.type=='gs'){
        id = '10'
      }else if(this.initData.type=='cs'){
        id = '20'
      }
      else if(this.initData.type=='zl'){
        id = '30'
      }
      else if(this.initData.type=='sb'){
        id = '40'
      }else if(this.initData.type=='qy'){
        id = '50'
      }else if(this.initData.type=='zz'){
        id = '60'
      }else if(this.initData.type=='js'){
        id = '70'
      }else if(this.initData.type=='fl'){
        id = '80'
      }
      //获取广告
      this.$ajax.get(URL.findInfo_adv+'/'+id).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.banner_adv = res.data.obj;
          // console.log(this.adverList);
        }
      });
    },
      // tabs切换
    tabsChange(e){
      console.log(e,"外层")
      if(e!='0'){
         this.$refs.footers.appointmentClick();
      }
    },
    changeTab() {
      this.getDetail();
    },
    // 办理材料菜单
    banliChange(e, index) {
      this.banliActive = index;
      console.log(e.content)
      this.banliContent = e.content;
    },
     // 预约咨询
    appointmentClick(){
      this.$refs.footers.appointmentClick()
    },
    // 详情
    goDetail(e, type) {
      console.log(e);
      this.$router.push({
        name: "companyRegister",
        query: {
          id: e.id,
          name: e.name,
          type: type,
        },
      });
    },
  },
};
</script>
<style >
  .question-box {
  margin-top: 50px;
}
 .question-box  .question-num {
  width: 35px;
  height: 35px;
  color: #116bff;
  border: 1px solid #eee;
  text-align: center;
  line-height: 35px;
  border-radius: 9px;
  margin-right: 15px;
}
 .question-box  .question-left {
  width: 48%;
  margin-right: 4%;
  font-size: 14px;
}
 .question-box .question-right {
  width: 48%;
  font-size: 14px;
}
 .question-box .question-card {
  margin-bottom: 30px;
}
.banli_remark {
  margin-top: 5px;
  color: #888;
  font-size: 14px;
}
.banli_right {
  width: 350px;
}
.banli_right > div {
  margin-top: 20px;
}
</style>
<style scoped>
.tcBox {
  width: 28%;
  padding: 20px;
  background-image: linear-gradient(#ecf4ff, #fff);
  border-radius: 10px;
  text-align: center;
}
.tcBox .tc_title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}
.tcBox .tc_desc {
  color: #666;
  font-size: 16px;
  margin-bottom: 20px;
}
.tcBox .tc_main {
  color: #666;
  line-height: 30px;
  font-size: 16px;
  height: 90px;
  margin-bottom: 10px;
}
.tc_main span {
  margin-left: 5px;
}
.detailBtn {
  background-image: linear-gradient(to right, #219dfe, #67dfd1);
  width: 100%;
  height: 50px;
  text-align: center;
  color: #fff;
  line-height: 50px;
  border-radius: 7px;
  margin-top: 10px;
  cursor: pointer;
}
.serve_card {
  width: 20%;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  height: 100px;
  margin-top: 10px;
  margin-right: 3%;
  position: relative;
  cursor: pointer;
}
.serve_card:nth-child(4n) {
  margin-right: 0px;
}
.blueBlock {
  position: absolute;
  left: 0px;
  top: 18px;
  width: 10px;
  height: 15px;
  background: #116bff;
}
.serve_card .serve_title {
  font-weight: bold;
}
.serve_card .serve_desc {
  color: #888;
  font-size: 14px;
  overflow: hidden;
  margin: 5px 0;
  height: 50px;
}
.zx-box {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.zx—btn {
  padding: 10px 30px;
  border: 1px solid #116bff;
  color: #116bff;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
}

.question-title {
  text-align: center;
  font-weight: bold;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}
.question-desc {
  color: #888;
  text-align: center;
  margin-top: 10px;
}



.banli_li {
  width: 33%;
  height: 50px;
  text-align: center;
  color: #116bff;
  line-height: 50px;
  border-radius: 7px;
  margin-top: 10px;
  cursor: pointer;
}
.banli_li_select {
  width: 33%;
  background-image: linear-gradient(to right, #219dfe, #67dfd1);
  transition: all 1s ease;
  height: 50px;
  text-align: center;
  color: #fff;
  line-height: 50px;
  border-radius: 7px;
  margin-top: 10px;
  cursor: pointer;
}
@media (min-width:768px){
  .banliMain {
  background: url("../../assets/img/bg/bg3.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 100%;
  padding: 60px 0;
  height: 550px;
}
.banli_box {
  width: 1200px;
  margin: 0 auto;
}
.banli_menu {
  width: 900px;
  margin: 0 auto;
}
.question {
  padding: 50px 0;
  width: 1200px;
  margin: 0 auto;
}
}
@media (max-width:768px){
  .mobile_tc{
    width: 100%;
    overflow-x: scroll;
  }
  .mobile_tcBox{
    width: 800px;
  }
  .banliMain {
  background: url("../../assets/img/bg/bg3.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 100%;
  padding: 60px 0;
  height: 550px;
  overflow-x: scroll;
}
  .banli_li{
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }
  .banli_li_select{
    font-size: 12px;
    overflow: hidden;
     white-space: nowrap;
}
.banli_box{
  width: 600px;
}
.banli_box img{
height: 115px!important;
    margin-right: 10px!important;
}
}
</style>
