import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/home.vue'
import service from '@/views/service/service.vue'
import aboutUs from '@/views/service/aboutUs.vue'
import searchList from '@/views/service/searchList.vue'
import business from '@/views/business/business.vue'
import companyRegister from '@/views/companyRegister/companyRegister.vue'
import userManage from '@/views/userManage/index.vue'
import knowledge from '@/views/knowledge/knowledge.vue'
import knowledgeList from '@/views/knowledge/knowledgeList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gs',
    name: 'gs',
    component: business
  },
  {
    path: '/cs',
    name: 'cs',
    component: business
  },
  {
    path: '/zl',
    name: 'zl',
    component: business
  },
  {
    path: '/sb',
    name: 'sb',
    component: business
  },
  {
    path: '/qy',
    name: 'qy',
    component: business
  },
  {
    path: '/zz',
    name: 'zz',
    component: business
  },
  {
    path: '/js',
    name: 'js',
    component: business
  },
  {
    path: '/fl',
    name: 'fl',
    component: business
  },

  {
    path: '/service',
    name: 'service',
    component: service
  },
  {
    path: '/companyRegister',
    name: 'companyRegister',
    component: companyRegister
  },
  {
    path: '/userManage',
    name: 'userManage',
    component: userManage
  },
  {
    path: '/zxzs',
    name: 'zxzs',
    component: knowledge
  },
  {
    path: '/knowledgeList',
    name: 'knowledgeList',
    component: knowledgeList
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/searchList',
    name: 'searchList',
    component: searchList
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to,from, next) => {
  window.scrollTo(0,0)
  })
export default router
