<template>
  <div class="">
    <headers :activeName="menuActive"></headers>
    <div class="main">
      <!-- 导航 -->
      <div class="nav">
        <span>首页></span>
        <span>{{form.title}}</span>
      </div>
      <!-- 内容块 -->
      <div class="_flex">
        <div class="content-left">
          <div class="title">{{form.title}}</div>
          <div class="content-desc _marginTop _flex _justify_center">
            <!-- <div style="margin-right:20px">
              <span></span>
              <span>587点赞</span>
            </div>
            <div>
              <span></span>
              <span>分享</span>
            </div> -->
          </div>
          <div class="_marginTop" style="min-height: 500px;">
            <div v-html="form.content"></div>
          </div>
           <!-- 广告图 -->
      <!-- <img
        class="_marginTop"
        src="@/assets/img/banner3.png"
        style="width: 100%; border-radius: 10px"
      /> -->
          <!-- <div class="_marginTop">
            <div class="tj_title">相关推荐：</div>
            <div class="tj_content">
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
            </div>
          </div> -->
           <div class="_marginTop">
            <!-- <div class="tj_title _flex" style="align-items: baseline;">
              <div>标签：</div>
              <div style="font-weight: normal;">
                <span class="tj_span">专精特新企业</span>
                <span class="tj_span">专精特新企业</span>
                <span class="tj_span">专精特新企业</span>
              </div>
            </div> -->
            <div style="color:#888">版权声明:本站刑载的所有内容[包括但不限于文字、图片、视频和音频等]所有权均归公司，未经作者许可，任何人不得转载。</div>
          </div>
        </div>
        <div class="content-right"  v-show="deviceType=='PC'">
         <appointment></appointment>
         <!-- 最新资讯 -->
              <news></news>
        </div>
      </div>
    </div>
    <footers class="_marginTop" :activeName="menuActive"></footers>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import {
		isDeviceType
	} from '@/utils.js'
import appointment from "@/components/appointment/appointment";
import news from "@/components/information/news";
import headers from "@/components/serveHead/serveHead";
import footers from "@/components/pageBottom/pageBottom";
export default {
  components: {
    headers,
     news,
    appointment,
    footers,
  },
  data() {
    return {
      deviceType:"",
      form:{},
      menuActive:"",
    };
  },
  watch:{
    $route(to, from) {
      // 对路由变化作出响应...
      console.log(" 对路由变化作出响应...");
      this.getDetail();
    },
  },
  created() {
       this.deviceType = isDeviceType()
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.initData = this.$route.query;
      this.menuActive="aboutUs"
      console.log(this.initData);
      let id;
      if(this.initData.id){
          id = this.initData.id;
      }else{
        id="4"
      }
       this.$ajax.get(URL.aboutUsDetail + '/'+id).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form = res.data.obj;
          this.$forceUpdate()
        }
      });
    }, 
  }
};
</script>

<style scoped>

.nav {
  padding: 20px 0;
  color: #888;
  font-size: 14px;
}
.content-left {
  width: 80%;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
  min-height: 500px;
}
.content-right {
  width: 20%;
  min-height: 500px;
  padding: 0 15px;
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}
.content-desc{
  font-size: 14px;
  color: #888;
}
.tj_title{
  color: #116BFF;
  font-weight:bold;
  padding-bottom: 10px;
}
.tj_content{
     font-size: 13px;
    line-height: 28px;
}
.tj_span{
  font-size: 13px;
  margin-right: 10px;
}
@media (min-width:768px){
.main {
  width: 1200px;
  margin: 0 auto;
}
}
@media (max-width:768px){

}
</style>
