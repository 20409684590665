<template>
  <div id="app">
    <router-view/>
    <asides v-show="deviceType=='PC'"></asides>
  </div>
</template>
<script>
import asides from "@/components/asides/asides";
import {
		isDeviceType
	} from '@/utils.js'
export default {
  components:{
    asides
  },
  data(){
    return{
      deviceType:"",//设备
    }
  },
  created(){
     this.deviceType = isDeviceType()
  }
}
</script>
<style>
.index_tabBox .el-tabs__item.is-active{
  color: #1375ff!important;
}
.index_tabBox .el-tabs__active-bar{
  background-color: #1375ff!important;
}
.index_tabBox .el-tabs__nav-wrap::after{
  background-color: #fff!important;
}
.searchBox .el-tabs__item{
  font-size: 20px!important;
}
.searchBox .el-tabs__item.is-active{
  color: #1375ff!important;
}
.searchBox .el-tabs__active-bar{
  background-color: #1375ff!important;
}
.searchBox .el-tabs__nav-wrap::after{
  background-color: #fff!important;
}
.searhInput .el-input__inner{
  height: 45px!important;
}
.footMain .el-tabs__item{
  color: #fff!important;
}
.footMain .el-tabs__item.is-active{
  color: #3e81ff!important;
  font-weight: bold;
}
.footMain .el-tabs__active-bar{
  background-color: #1375ff!important;
}
.footMain .el-tabs__nav-wrap::after{
  background-color: #fff!important;
}
.footMain .el-tabs__nav-wrap::after{
  background-color: #29325f!important;
}
.service_tabBox .el-tabs__item{
  color: #fff!important;
}
.service_tabBox .el-tabs__item.is-active{
  color: #fff!important;
  font-weight: bold;
}
.service_tabBox .el-tabs__active-bar{
  background-color: #fff!important;
}
.service_tabBox .el-tabs__nav-wrap::after{
  background-color:transparent!important;
}
.middle-box .el-table{
 background-color:transparent!important;
}
.middle-box .el-table tr{
 background-color:transparent!important;
}
.middle-box .el-table th.el-table__cell{
 background-color:transparent!important;
}
.el-table th{
  color: #000;
  font-weight:bold ;
    border-bottom: 2px solid #116bff !important;
}
.citySelect .el-input__inner{
  height: 30px !important;
}
@media (max-width:768px){
.bottomBorder img{
 max-width: 100%!important;
}
.el-drawer__body{
  background: #2C59A3;
}
}
</style>
