<template>
  <div class="">
    <!-- 搜所栏 -->
    <div class="searchBox" style="margin-top: 20px">
      <div class="_flex _justify_center">
        <el-tabs v-model="searchActive" class="tabs" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, index) in searchMenuList"
            :label="item.name"
            :name="item.code"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="selectData _flex">
        <el-input
          class="searhInput"
          v-model="searchValue"
          placeholder="请输入服务名称，如“商标注册”"
        ></el-input>
        <div class="selectBtn" @click="searchClick">查询</div>
      </div>
    </div>
    <!-- 热门搜索 -->
    <div class="hotsearch _flex _justify_center align-items_center">
      <div class="_flex align-items_center">
        <img
          src="@/assets/img/search-key.png"
          style="width: 20px; height: 20px"
        />
        <span>热门搜索:</span>
      </div>
      <div>
        <span
          style="cursor: pointer"
          v-for="(item, index) in hotList"
          :key="index"
          @click="hotSearch(item)"
          >{{ item.name }}</span
        >
      </div>
    </div>
    <div class="bottomBorder"></div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      searchValue: "",
      searchActive: "serve",
      searchMenuList: [
        {
          name: "查服务",
          code: "serve",
        },
        {
          name: "免费核名",
          code: "hm",
        },
        {
          name: "免费起名",
          code: "qm",
        },
      ],
      hotList: [
        {
          name: "商标注册",
        },
        {
          name: "公司注册",
        },
        {
          name: "专利申请",
        },
      ],
    };
  },
  methods: {
    searchClick(e) {
      if (!this.searchValue) {
        this.$message.error("请输入服务名称!");
        return;
      }
      this.$router.push({
        name: "searchList",
        query: {
          name: this.searchValue,
        },
      });
    },
    hotSearch(e) {
      this.$router.push({
        name: "searchList",
        query: {
          name: e.name,
        },
      });
    },
    // 点击菜单切换
handleClick(e){
  console.log(e)
  this.$emit("tabsChange",e.index)
},
  },
};
</script>

<style scoped>
.selectBtn {
  cursor: pointer;
}
@media (min-width: 768px) {
  .tabs{
    width: 350px;
  }
  .bottomBorder {
    height: 50px;
    border-bottom: 1px solid #ecf5ff;
  }
}
@media (max-width: 768px) {
  .tabs{
    width: 300px;
  }
  .bottomBorder {
    height: 10px;
    border-bottom: 1px solid #ecf5ff;
    margin-bottom: 10px;
  }
}
</style>
