 <template>
  <div  v-loading="loading">
    <div class="searchWidth">
      <el-input prefix-icon="el-icon-search" v-model="searchName"
        @keyup.enter.native="searchAllCompany" placeholder="查询服务名称"></el-input>
    </div>
    <div class="_marginTop" style="height:500px">
      <el-table :data="form.rows" style="width: 100%">
      <el-table-column prop="contractCode" label="合同编号" width="180">
      </el-table-column>
      <el-table-column prop="serviceName" label="服务名称"> </el-table-column>
       <el-table-column prop="serviceDate" label="服务日期">
      </el-table-column>
       <el-table-column prop="money" width="180" label="合同金额（元）">
      </el-table-column>
       <el-table-column prop="status" width="180" label="服务进度">
      </el-table-column>
       <el-table-column  label="评价" width="180">
         <template slot-scope="scope">
           <div>
              <el-rate v-model="scope.row.evaluationNumber" @change="changeStar(scope.row)"></el-rate>
           </div>
         </template>
      </el-table-column>
    </el-table>
    </div>
   <div class="_marginTop" style="    text-align: center;" v-if="form.rows.length!=0">
                <el-pagination background layout="prev, pager, next" :total="form.total" @current-change="pageChange"> </el-pagination>
            </div>
  </div>
</template>

  <script>
import { URL, BASEURL } from "@/urlpath.js";
export default {
  name: "home",
  data() {
    return {
      loading:false,
      searchName:"",
      form: {
        rows:[]
      },
       page:1,
       size:12
    };
  },
   created() {
    this.getDetail();
  },
  methods: {
     getDetail() {
         this.loading = true;
       this.$ajax.post(URL.USER_SERVICELIST,{
         name:this.searchName,
         pageNum:this.page,
        pageSize:this.size,
       }).then((res) => {
        console.log(res);
        this.loading = false;
        if (res.data.code == "0000") {
          this.form = res.data.obj;
        }else{
            this.$message.error('服务器错误!');
        }
      }).catch(err=>{
          this.loading = false;
        });
    },
    // 搜所
    searchAllCompany(){
      this.getDetail()
    },
     // 详情
    goDetail(e,type) {
      console.log(e)
      this.$router.push({
        name: "companyRegister",
        query: {
          id:e.commodityId,
          name:e.name,
          type:type
        },
      });
    },
    // 修改星星评价
    changeStar(e,){
      console.log(e)
       this.$ajax.post(URL.USER_EVALUATION,{
        id:e.id,
        evaluationNumber:e.evaluationNumber,
       }).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form = res.data.obj;
        }else{
            this.$message.error('服务器错误!');
        }
      }).catch(err=>{
        });
    },
      // 分页
    pageChange(e){
      console.log(e)
      this.page = e;
    },
  },
};
</script>

<style scoped>
@media (min-width:768px){
  .searchWidth{
    width:500px;
  }
}
@media (max-width:768px){

}
</style>
