<template>
<div>
  <div class="service">
    <div class="header _flex align-items_center _justify_between">
      <img src="@/assets/img/logo_b.png"  class="webLogo" />
        <img src="@/assets/img/mobile/whiteMenu.png"  class="webLogo"  v-show="deviceType=='mobile'" @click="mobileMenu"/>
      <div class="_flex align-items_center" style="color: #fff;" v-show="deviceType=='PC'">
        <div
          class="_flex"
          style="margin-right: 40px;  align-items: baseline"
        >
          <img
            src="@/assets/img/phone_white.png"
            style="height: 15px; margin-right: 5px"
          />
          <span>16601116787</span>
        </div>
         <div style="margin-right: 10px;">
          <span style="cursor: pointer;" @click="register" v-show="!islogin">免费注册</span>
          <span style="cursor: pointer;" @click="userManage" v-show="islogin">管理控制中心</span>
        </div>
        <div style="cursor: pointer;" @click="login" v-show="!islogin">登录</div>
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="service_tabBox"  v-show="deviceType=='PC'"
            @mouseleave="mouseMenuLeave" 
    >
      <div class="_flex">
        <div class="menuDefault"
         @mouseover="mouseMenuOver(item)"
      :style="{'border-color':item.code==activeName?'#fff':'transparent'}" v-for="(item, index1) in menuPCList" :key="index1" @click="handleClick(item)">{{item.name}}</div>
      </div>
      <div class="showMenuBox" v-show="showMenuAlert">
        <div class="showSubBox">
          <div class="_flex showSubLi" v-for="(item,index) in menuAlertData" :key="index">
            <div class="showMenuTitle">{{item.name}}</div>
            <div  class="showMenuInfo">
              <span v-for="(ite,idx) in item.children" :key="idx" @click="goDetail(ite,'')">{{ite.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in menuList"
          :label="item.name"
          :name="item.code"
        ></el-tab-pane>
      </el-tabs> -->
    </div>
     <login ref="login"   @refreshPage="refreshPage"></login>
    <menu-aside ref="mobileMenu"   v-show="deviceType=='mobile'"></menu-aside>
  </div>
  <div  v-show="deviceType=='PC'" style="height:170px"></div>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import login from "@/components/login/login";
import menuAside from "@/components/mobile/menuAside";
import {
		isDeviceType
	} from '@/utils.js'
export default {
  name: 'home',
  props:{
    activeName: {
      type: String,
      default: "Home",
    },
  },
  components: {
     menuAside,
    login
  },
  data(){
    return{
       islogin:false,//是否登录
       showMenuAlert:false,//菜单下拉框
       deviceType:"",//设备
        menuActive: "",
        menuPCList:[],//PC动态菜单栏
        menuAlertData:[], //菜单浮动框子数据
      menuList: [
        {
          name: "首页",
          code: "Home",
        },
        {
          name: "工商服务",
          code: "gs",
        },
        {
          name: "财税服务",
          code: "cs",
        },
        {
          name: "专利服务",
          code: "zl",
        },
        {
          name: "商标版权",
          code: "sb",
        },
        {
          name: "企业认证",
          code: "qy",
        },
        {
          name: "资质项目",
          code: "zz",
        },
        {
          name: "技术服务",
          code: "js",
        },
        {
          name: "法律服务",
          code: "fl",
        },
         {
          name: "资讯知识库",
          code: "zxzs",
        },
        {
          name: "关于我们",
          code: "aboutUs",
        },
      ],
    }
  },
  created(){
    this.deviceType = isDeviceType()
     this.refreshPage()
     this.getNavList()
  },
  methods:{
    getNavList() {
       this.$ajax.get(URL.NAV_MENU,{
         name:this.searchName,
         pageNum:this.page,
        pageSize:this.size,
       }).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.menuPCList = res.data.obj;
        }else{
            this.$message.error('服务器错误!');
        }
      }).catch(err=>{
        });
    },
     // 导航栏移入
    mouseMenuOver(e) {
      console.log(e);
      this.menuAlertData = e.children;
      if(this.menuAlertData.length==0){
        this.showMenuAlert = false;
      }else{
        this.showMenuAlert = true;
      }
    },
     // 导航栏移出
    mouseMenuLeave(e) {
      this.showMenuAlert = false;
    },
      // 详情
    goDetail(e,type) {
      console.log(e)
      this.$router.push({
        name: "companyRegister",
        query: {
          id:e.id,
          name:e.name,
          type:type
        },
      });
    },
     // 刷新
    refreshPage(){
      if(sessionStorage.getItem('Authorization')){
        this.islogin=true;
      }
    },
    // 登录
    login(){
      this.$refs.login.showLogin()
    },
    // 注册
    register(){
      this.$refs.login.showRegist()
    },
     // 跳转用户中心
    userManage(){
      this.$router.push({ 
        name: "userManage"
      });
    },
     // 手机端菜单
      mobileMenu(){
        this.$refs.mobileMenu.showMenu()
      },
    handleClick(e) {
      console.log(e);
        this.$router.push({ 
        name: e.code,
        query:{
           name:e.name,
           type:e.code
        }
      });
      this.$emit('changeTab')
    },
  }
}
</script>

<style scoped>

@media (min-width:768px){
.service{
   background: url("../../assets/img/bg/bg2.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 100%;
  padding: 30px 0 20px 0;
      position: fixed;
    top: 0px;
    z-index: 666;
}
.header {
  width: 1200px;
  margin: 0 auto;
}
.webLogo{
  height: 50px;
}

/* 导航栏 */
.service_tabBox{
   width: 1200px;
  margin: 0 auto;
  position: relative;
  height: 60px;
 padding: 10px 0px 0 30px;
}
  .menuDefault{
        padding-bottom: 10px;
        cursor: pointer;
    color: #fff;
    margin: 10px 37px 0 0;
    font-size: 15px;
      transition: all .5s ease;
        border-bottom: 2px solid #fff;
  }
  .menuDefault:hover{
    font-weight:bold ;
  } 
.showMenuBox{
  position: absolute;
  width: 1200px;
  min-height: 200px;
  background: #fff;
  left: 50%;
   top: 60px;
  z-index: 111;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
}
.showMenuBox{
    padding: 20px;
    box-sizing: border-box;
    transition: all .3s ease;
}
.showMenuTitle{
      color: #333;
    width: 110px;
font-weight:bold ;
}
.showSubLi{
  padding-bottom: 20px;
}
.showMenuInfo{
      width: 1000px;
    color: #555;
    line-height: 30px;
  
}
.showMenuInfo span{
    margin-right: 25px;
    font-size: 15px;
    cursor: pointer;
}
.showMenuInfo span:hover{
  color: #409EFF;
}
}
@media (max-width:768px){
.service{
   background: url("../../assets/img/bg/bg2.png") no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  width: 100%;
  padding: 30px 0 10px 0;
}
.webLogo{
  height: 30px;
  padding: 0 15px;
}
}
</style>