<template>
  <div class="">
    <headers :activeName="menuActive"></headers>
    <div class="main" v-loading="loading">
      <!-- 导航 -->
      <div class="nav">
        <span>首页></span>
        <span>{{ form.commodityInfo.name }}</span>
      </div>
      <!-- 内容块 -->
      <div class="_flex">
        <div class="content-left">
          <!-- 公司注册 -->
          <div class="_flex mobileCommodity">
            <div class="imgBox_left">
              <img
                v-if="form.commodityInfo.imgUrl"
                :src="form.commodityInfo.imgUrl"
                style="height: 300px; width: 100%; border-radius: 10px"
              />
              <img
                v-else
                src="@/assets/img/imgEmpty.jpg"
                style="height: 300px; width: 100%; border-radius: 10px"
              />
            </div>
            <div class="rej_right">
              <div class="rej_title">{{ form.commodityInfo.name }}</div>
              <div class="topBox_desc">
                {{ form.commodityInfo.brief }}
              </div>
              <div class="_flex align-items_center" style="margin-top: 15px">
                <span  style="font-size: 14px;color: #888;">价格：</span>
                <div v-if="payPrice!='0'">
                  <span style="font-size: 20px; color: #ff0000">￥</span>
                <span style="font-size: 25px; color: #ff0000"
                  >{{ payPrice }}起</span
                >
                </div>
                <div v-else>
                   <span style="font-size: 18px; color: #ff0000">面议</span>
                </div>
              </div>
              <div class="_flex" style="margin-top: 10px">
                <div
                  class="rej_intro"
                  v-for="(item, index) in brandList"
                  :key="index"
                >
                  <img
                    src="@/assets/img/pro-ico-1.png"
                    style="width: 15px; height: 15px; margin-right: 6px"
                  />
                  <span>{{ item }}</span>
                </div>
              </div>
              <div class="_flex align-items_center" style="margin-top: 10px">
                <div class="rej-lable">服务地区：</div>
                <el-cascader
                  class="citySelect"
                  ref="cityCasc"
                     placeholder="请输入关键字搜索"
                  size="large"
                  filterable
                  :options="options"
                  :props="{
                    label: 'name',
                    value: 'id',
                  }"
                  v-model="selectedOptions"
                  @change="handleChange"
                >
                </el-cascader>
              </div>
              <div
                class="_flex"
                style="margin-top: 15px"
                v-for="(item, index) in form.specs"
                :key="index"
              >
                <div class="rej-lable">{{ item.typeName }}：</div>
                <div class="rej-project">
                  <span
                    v-for="(ite, idx) in item.typeList"
                    :key="idx"
                    @click="changeTypeName(ite, item, idx)"
                    :style="{
                      'border-color': idx == item.active ? '#116bff' : '#eee',
                    }"
                    >{{ ite.name }}</span
                  >
                </div>
              </div>
              <!-- <div class="_flex" style="margin-top: 10px">
                <div class="rej-lable">服务内容：</div>
                <div class="rej-program">
                  <span
                    v-for="(item, index) in selectObj.company"
                    :style="{
                      'border-color':
                        index == companyActive ? '#116bff' : '#888',
                    }"
                    >有限责任公司</span
                  >
                </div>
              </div> -->
              <div class="_flex _marginTop">
                <div class="submit_left" @click="submitNeed">
                  <img
                    src="@/assets/img/Submit-ico.png"
                    style="width: 15px; height: 15px; margin-right: 3px"
                  />
                  <span>提交需求</span>
                </div>
                <div
                  class="submit_right"
                  @click="collect"
                  :style="{ 'border-color': isCollect ? '#b9b94c' : '#b3b3b3' }"
                >
                  <div v-if="isCollect" class="_flex">
                    <img
                      src="@/assets/img/collect_y.png"
                      style="width: 20px; height: 20px; margin-right: 3px"
                    />
                    <span style="color: #b9b94c">已收藏</span>
                  </div>
                  <div v-else class="_flex">
                    <img
                      src="@/assets/img/collect_n.png"
                      style="width: 20px; height: 20px; margin-right: 3px"
                    />
                    <span>收藏</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomBorder _marginTop">
            <div class="index_tabBox">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                  v-for="(item, index) in form.contents"
                  :key="index"
                  :label="item.title"
                  :name="item.id"
                ></el-tab-pane>
              </el-tabs>
            </div>
            <div>
              <div v-html="content"></div>
            </div>
          </div>
          <!-- 广告图 -->
          <img
            class="_marginTop"
            v-if="banner_adv.length != 0"
            :src="banner_adv[0].imgUrl"
            style="width: 100%; border-radius: 10px"
          />
          <!-- <div class="_marginTop">
            <div class="tj_title">相关推荐：</div>
            <div class="tj_content">
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
              <div>列表列表列表列表列表列表列表列表列表列表列表列表</div>
            </div>
          </div> -->
          <div class="_marginTop">
            <!-- <div class="tj_title _flex" style="align-items: baseline">
              <div>标签：</div>
              <div style="font-weight: normal">
                <span class="tj_span">专精特新企业</span>
                <span class="tj_span">专精特新企业</span>
                <span class="tj_span">专精特新企业</span>
              </div>
            </div> -->
            <div style="color: #888">
              版权声明:本站刑载的所有内容[包括但不限于文字、图片、视频和音频等]所有权均归公司，未经作者许可，任何人禁止转载。
            </div>
          </div>
        </div>
        <div class="content-right" v-show="deviceType == 'PC'">
          <appointment></appointment>
          <!-- 最新资讯 -->
          <news></news>
        </div>
      </div>
    </div>
    <footers class="_marginTop" :activeName="menuActive"></footers>
    <login ref="login" @refreshPage="refreshPage"></login>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import { isDeviceType } from "@/utils.js";
import login from "@/components/login/login";
import appointment from "@/components/appointment/appointment";
import news from "@/components/information/news";
import headers from "@/components/serveHead/serveHead";
import footers from "@/components/pageBottom/pageBottom";
export default {
  components: {
    login,
    appointment,
    news,
    headers,
    footers,
  },
  data() {
    return {
      form: {
        city: [],
        commodityInfo: {},
        content: [],
        specs: [],
      },
      deviceType: "",
      loading: false,
      rightList: [{}, {}, {}, {}, {}, {}, {}, {}],
      options: [],
      brandList: [],
      isCollect: false,
      selectedOptions: [],
      cityName: "",
      activeName: "",
      payPrice: "", //价格
      content: "", //富文本
      companyActive: 0, //公司性质
      initData: "",
      menuActive: "",
      cityId: "",
      banner_adv: [], //广告
    };
  },
  watch: {
    $route(to, from) {
      // 对路由变化作出响应...
      console.log(" 对路由变化作出响应...");
      this.getDetail();
      this.getAdvertisement();
    },
  },
  created() {
    this.deviceType = isDeviceType();
    this.getDetail();
    this.getAdvertisement();
  },
  methods: {
    getDetail() {
      this.initData = this.$route.query;
      this.menuActive = this.initData.type;
      console.log(this.initData);
      this.loading = true;
      this.$ajax
        .get(URL.commodityDetailApi + "/" + this.initData.id)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.code == "0000") {
            this.form = res.data.obj;
            this.brandList = this.form.commodityInfo.introduce.split(";");
            this.isCollect = this.form.commodityInfo.storeFlag;
            this.payPrice = this.form.commodityInfo.price;
            this.options = this.form.cityInfo;
            this.activeName = res.data.obj.contents[0].id;
            this.content = this.form.contents[0].content;
            this.browsehistory();
            console.log(this.form);
          } else {
            this.$message.error("服务器错误!");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取广告
    getAdvertisement() {
      let id;
      console.log(this.initData.type);
      if (this.initData.type == "gs") {
        id = "10";
      } else if (this.initData.type == "cs") {
        id = "20";
      } else if (this.initData.type == "zl") {
        id = "30";
      } else if (this.initData.type == "sb") {
        id = "40";
      } else if (this.initData.type == "qy") {
        id = "50";
      } else if (this.initData.type == "zz") {
        id = "60";
      } else if (this.initData.type == "js") {
        id = "70";
      } else if (this.initData.type == "fl") {
        id = "80";
      }
      //获取广告
      this.$ajax.get(URL.findInfo_adv + "/" + id).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.banner_adv = res.data.obj;
          // console.log(this.adverList);
        }
      });
    },
    // 城市切换
    handleChange(value) {
      console.log(this.$refs["cityCasc"].getCheckedNodes()[0].pathLabels);
      let cityName = this.$refs["cityCasc"].getCheckedNodes()[0].pathLabels;
      this.cityName = cityName.join(",");
      console.log(value[1]);
      this.cityId = value[1]; //服务地区-地级市id
      this.getGuige()
    },
    // 查询规格
    getGuige(){
       this.$ajax.get(URL.specsData + "/" + this.initData.id+ "/" + this.cityId).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form.specs = res.data.obj.specs;
          console.log(this.form.specs,"specs");
          this.$forceUpdate()
        }
      });
    },
    // 公司性质切换
    changeTypeName(e, item, index) {
      console.log(e);
      console.log(item);
      console.log(index)
      item.active = index;
      item.str = e.name;
      
        console.log(this.form.specs,"specs");
        let specsArr = this.form.specs;
          var max=Number(e.price);
        specsArr.forEach(e => {
          let priceObj = e.typeList[e.active]?e.typeList[e.active]:0; //如果active为-1，则价格为0
         console.log( priceObj,"==========")
        //  循环数组取出最大值
            if(max<Number(priceObj.price)){
            max=e.typeList[e.active].price;
            }
        });
        console.log(max,"最大")
        this.payPrice = max;
      this.$forceUpdate()
      
    },
    // 登录成功刷新
    refreshPage() {
      this.$refs.login.refreshPage();
      this.getDetail();
    },
    // 提交需求
    submitNeed() {
      if (sessionStorage.getItem("Authorization")) {
        if (this.selectedOptions.length == 0) {
          this.$message.error("服务地区不能为空！");
          return;
        }
        console.log(this.form.specs);
        let specs = this.form.specs;
        let specsArr = [];
        for (let i = 0; i < specs.length; i++) {
          console.log(specs[i]);
          specsArr.push(specs[i].typeName + ":" + specs[i].str);
        }
        let selectStr = specsArr.join(",");
        let remark =
          "价格:" + this.payPrice + "," + this.cityName + "," + selectStr;
        console.log(remark);
        this.$ajax
          .post(URL.submitNeed, {
            commodityId: this.form.commodityInfo.id,
            remark: remark,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == "0000") {
              this.$confirm(
                "您的需求申请已经提交成功，感谢您的支持！",
                "提示",
                {
                  confirmButtonText: "确定",
                  showCancelButton: false,
                  type: "success",
                }
              ).then(() => {
                location.reload();
              });
            } else {
              this.$message.error(res.data.obj);
            }
          });
      } else {
        this.$refs.login.showLogin();
      }
    },
    // 收藏
    collect() {
      if (sessionStorage.getItem("Authorization")) {
        let collectStatus;
        if (this.isCollect) {
          collectStatus = 0;
        } else {
          collectStatus = 1;
        }
        this.$ajax
          .post(URL.usercollect, {
            commodityId: this.form.commodityInfo.id,
            collectStatus: collectStatus,
          })
          .then((res) => {
            console.log(res.data.obj);
            if (res.data.code == "0000") {
              this.isCollect = res.data.obj.flag;
              this.$message({
                message: res.data.obj.msg,
                type: "success",
              });
            }
          });
      } else {
        this.$refs.login.showLogin();
      }
    },
    // 新增浏览记录
    browsehistory() {
      if (sessionStorage.getItem("Authorization")) {
        this.$ajax
          .post(URL.browsehistory, {
            commodityId: this.form.commodityInfo.id,
          })
          .then((res) => {
            console.log(res.data.obj);
            if (res.data.code == "0000") {
            }
          });
      }
    },
    handleClick(e) {
      console.log(e.index);
      this.activeName = e.name;
      this.content = this.form.contents[e.index].content;
    },
  },
};
</script>

<style scoped>
.nav {
  padding: 20px 0;
  color: #888;
  font-size: 14px;
}

.content-right {
  width: 20%;
  min-height: 500px;
  padding: 0 15px;
}
.topBox_desc {
  color: #888;
  margin-top: 10px;
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}
.content-desc {
  font-size: 14px;
  margin-top: 10px;
  color: #888;
}

.tj_title {
  color: #116bff;
  font-weight: bold;
  padding-bottom: 10px;
}
.tj_content {
  font-size: 13px;
  line-height: 28px;
}
.tj_span {
  font-size: 13px;
  margin-right: 10px;
}

.rej_title {
  font-weight: bold;
  font-size: 20px;
}
.rej_intro {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #888;
  margin-right: 15px;
}
.rej-lable {
  font-size: 14px;
  color: #888;
  min-width: 70px;
}
.rej-project span {
  color: #888;
  font-size: 14px;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 3px 10px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  display: inline-block;
}
.rej-program span {
  color: #116bff;
  font-size: 14px;
  cursor: pointer;
  margin-right: 15px;
}
.submit_left {
  background-image: linear-gradient(to right, #219dfe, #67dfd1);
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.submit_right {
  width: 100px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
    box-sizing: border-box;
  border: 2px solid #b3b3b3;
  color: #b3b3b3;
  margin-left: 20px;
}
.bottomBorder {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 30px;
  min-height: 500px;
}
@media (min-width: 768px) {
  .main {
    width: 1200px;
    margin: 0 auto;
  }
  .content-left {
    width: 100%;
  }
  .imgBox_left {
    width: 40%;
  }
  .rej_right {
    width: 60%;
    padding: 0 15px;
  }
}
@media (max-width: 768px) {
  .mobileCommodity {
    display: block;
  }
  .content-left {
    width: 100%;
  }
  .imgBox_left {
    width: 100%;
  }
  .rej_right {
    padding: 15px;
  }
}
</style>
