<template>
  <div class="mainBox _flex"  v-loading="loading">
    <div style="cursor: pointer;">
      <el-upload :on-success="handleImgChange" :show-file-list="false" :headers="headObj" :action="BASEURL+'/user/uploadPhoto'">
       <img v-if="form.userPhoto" :src="form.userPhoto" style="width: 80px;height: 80px;" />
       <img v-else src="@/assets/img/cms/admin-img.png" style="width: 80px;height: 80px;" />
      <div style="text-align: center;margin-top: 5px;color: #888;">修改</div>
       </el-upload>
    </div>
    <div style=" width: 500px;">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="账户">
          <el-input v-model="username"  disabled></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="form.userNickname" maxlength="11"  placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input v-model="form.realName" maxlength="4" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.userPhone" maxlength="11" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="form.email" maxlength="40" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="微信号">
          <el-input v-model="form.userOpenid" maxlength="40" placeholder="请输入微信号"></el-input>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input v-model="form.companyName"  placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="企业地址">
          <el-input v-model="form.companyAddress"  placeholder="请输入企业地址"></el-input>
        </el-form-item>
        <el-form-item label="办公电话">
          <el-input v-model="form.companyPhone" maxlength="11" placeholder="请输入办公电话"></el-input>
        </el-form-item>
        <el-form-item label="企业邮箱">
          <el-input v-model="form.companyEmail" maxlength="40" placeholder="请输入企业邮箱"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
export default {
  name: "home",
  data() {
    return {
      loading:false,
      username:"",
      form: {
        userNickname:"",
        realName:"",
        userPhone:"",
        email:"",
        userPhoto:"",
        userOpenid:"",
        companyName:"",
          companyAddress:"",
           companyEmail:"",
            companyPhone:"",
        remark:""
      },
      headObj:{},
      BASEURL:BASEURL
    };
  },
  created(){
    this.headObj={
      Authorization:sessionStorage.getItem('Authorization')
    }
    this.getDetail()
  },
  methods: {
    getDetail(){
       this.username = sessionStorage.getItem('username')
       this.loading = true;
        this.$ajax.get(URL.USER_INFO).then((res) => {
          console.log(res)
            if (res.data.code == "0000") {
              let obj = res.data.obj
            this.form.userPhoto = obj.userPhoto;
            this.form.userNickname = obj.userNickname;
            this.form.realName = obj.realName;
            this.form.userPhone = obj.userPhone;
            this.form.email = obj.email;
            this.form.userOpenid  = obj.userOpenid ;
             this.form.companyName=obj.companyName;
          this.form.companyAddress=obj.companyAddress;
           this.form.companyEmail=obj.companyEmail;
            this.form.companyPhone=obj.companyPhone;
            this.form.remark = obj.remark;
            this.$forceUpdate()
            this.$emit('upDateImg', obj.userPhoto)
          }
           this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
    },
    // 上传头像
    handleImgChange(e){
      console.log(e)
      if( e.code=='0000'){
        this.form.userPhoto = e.obj
        this.$forceUpdate()
      }
    },
    onSubmit(){
      this.$ajax.post(URL.USER_DATE,this.form).then((res) => {
          if (res.data.code == "0000") {
             this.$message({
                message: res.data.obj,
                type: 'success'
              });
              this.getDetail()
          }
          else{
            this.$message.error('服务器错误!');
        }
          console.log(res)
        })
    }
  },
};
</script>

<style scoped>
</style>
