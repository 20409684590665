<template>
  <div class="">
    <headers :activeName="menuActive"></headers>
    <div class="main">
      <!-- 导航 -->
      <div class="nav">
        <span>首页></span>
        <span>{{initData.title}}</span>
      </div>
       <!-- 搜所栏 -->
      <hot-search @tabsChange="tabsChange"></hot-search>
      <!-- 内容块 -->
      <div class="_flex">
        <div class="content-left">
          <div class="title">{{initData.title}}</div>
          <div class="content-desc  _flex _justify_center">
            <div>服务企业专员用心服务 为企业跑专利</div>
          </div>
          <div>
            <div class="newsLi _flex align-items_center" v-for="(item,index) in form.rows" @click="goDetail(item)">
              <img :src="item.imgUrl" style="    width: 100px;margin-right: 20px;height: 100px;">
              <div>
                <div class="li-title">{{item.title}}</div>
                <div class="li-desc">{{item.description}}</div>
                <div class="li-time">{{item.createTime}}</div>
              </div>
            </div>
          </div>
          <div class="_marginTop" style="    text-align: center;">
                <el-pagination background layout="prev, pager, next" :total="form.total" @current-change="pageChange"> </el-pagination>
            </div>
        </div>
        <div class="content-right" v-show="deviceType=='PC'">
          <appointment></appointment>
         <!-- 最新资讯 -->
              <news></news>
        </div>
      </div>
    </div>
    <footers class="_marginTop" :activeName="menuActive"></footers>
  </div>
</template>

<script>
import { URL, BASEURL } from "@/urlpath.js";
import {
		isDeviceType
	} from '@/utils.js'
import appointment from "@/components/appointment/appointment";
import news from "@/components/information/news";
import headers from "@/components/serveHead/serveHead";
import hotSearch from "@/components/hotSearch/hotSearch";
import footers from "@/components/pageBottom/pageBottom";
export default {
  components: {
    appointment,
    news,
    headers,
    hotSearch,
    footers,
  },
  data() {
    return {
      form:{},
         deviceType:"",
      menuActive:'zxzs',
      page:1,
      pageSize:10,
    };
  },
   created() {
     this.deviceType = isDeviceType()
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.initData = this.$route.query;
      console.log(this.initData);
       this.$ajax.get(URL.zixunList + '/'+this.initData.id+"/"+this.page+"/"+this.pageSize).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.form = res.data.obj;
        }
      });
    }, 
    // tabs切换
    tabsChange(e){
      console.log(e,"外层")
      if(e!='0'){
         this.$refs.footers.appointmentClick();
      }
    },
    // 分页
    pageChange(e){
      console.log(e)
      this.page = e;
      this.getDetail()
    },
    goDetail(e){
      console.log(e)
      this.$router.push({
        name:"service",
        query:{
          id:e.id
        }
      })
    }
  }
};
</script>

<style scoped>

.nav {
  padding: 20px 0;
  color: #888;
  font-size: 14px;
}
.content-left {
  width: 80%;
  padding: 30px;
  min-height: 500px;
}
.content-right {
  width: 20%;
  min-height: 500px;
  padding: 0 15px;
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}
.content-desc{
  font-size: 14px;
  margin-top: 10px;
  color: #888;
}
.newsLi{
  margin-top: 20px;
  cursor: pointer;
}
.li-title{
  font-weight:bold ;
  font-size: 16px;
}
.li-desc{
  color: #888;
  font-size: 14px;
  margin-top: 10px;
}
.li-time{
  color: #888;
  font-size: 12px;
   margin-top: 10px;
}
@media (min-width:768px){
  .main {
  width: 1200px;
  margin: 0 auto;
}
}
@media (max-width:768px){

}
</style>
