// const BASEURL = "http://192.168.20.106:1214"  //卢刚
// const BASEURL = "http://192.168.30.102:8081"  //本地
// const BASEURL = "http://192.168.6.227:1214"  //内网测试
// const BASEURL = "http://60.28.43.115:8072"  //外网测试
const BASEURL = "https://api.zhihuovip.com"  //线上
const URL ={
    /*
        * 移动端验证 
     */
    VERTIFYWECHAT :BASEURL + '/api/user/wxlogin',  //移动端获取openid
    /*
        * 首页 
     */
    NAV_MENU:BASEURL+'/commoditycategory/list ',//导航栏
    HOME_SWIGER:BASEURL+'/banner/list',//轮播图
    HOME_ADVERTIS:BASEURL+'/advertisement/list', //广告位图
    commodityApi:BASEURL+'/commodity/list ', //商品
    commodityDetailApi:BASEURL+'/commodity/detail', //商品详情
    reservationApi:BASEURL+'/messagefeedback/add', //预约咨询 
    bottomArticles:BASEURL+'/article/bottomArticles', //热点资讯
    brandApi:BASEURL+'/basecontent/detail', //合作企业
    websiteInfo:BASEURL+'/basecontent/contentlist', //网站信息
    aboutUsDetail:BASEURL+'/basecontent/detail', //网站信息详情
    /*
        * 模块详情
     */
    commodityDetail:BASEURL + '/commodity/info',//查询工商服务 下 信息接口
    articleSwiper:BASEURL + '/article/imgarticle', //新闻轮播
    zixunArticle:BASEURL + '/article/articles', //资讯新闻块
    zixunList:BASEURL + '/article/pagelist', //资讯新闻列表
    zixunDetail:BASEURL + '/article/detail', //资讯新闻详情
    newZixunDetail:BASEURL + '/commodity/rightdata', //最新资讯
    submitNeed:BASEURL + '/userorder/add', //提交需求
    usercollect:BASEURL + '/usercollect/add ', //收藏
    browsehistory:BASEURL + '/userbrowsehistory/add', //新增浏览记录
    findInfo_adv:BASEURL + '/advertisement/findInfo', //每个模块的广告
    searchData:BASEURL + '/commodity/search', //搜所
    specsData:BASEURL + '/commodity/specs', //查询规格
    /*
        * 登录 
     */
    VERIFICATION_CODE :BASEURL + '/auth/code' ,//图形验证码
    REGIST_CODE :BASEURL + '/v1/code' ,//注册图形验证码
    REGISTSENDSMS_CODE :BASEURL + '/v1/registry/sendSMS' ,//注册发送短信验证码接口
    SENDSMS_CODE :BASEURL + '/v1/sendSMS' ,//登录发送短信验证码接口
    registryApi :BASEURL + '/user/registry' ,//用户注册
    MessageLogin :BASEURL + '/v1/phoneLogin' ,//短信验证码登录接口
    loginApi :BASEURL + '/user/login' ,//用户登录
    logoutApi :BASEURL + '/auth/logout' ,//退出登录
    updatePwdApi :BASEURL + '/user/updatePwd' ,//修改密码
    BIND_WECHAT :BASEURL + '/weixin_open/bind/qrcode' ,// 绑定微信时获取地址接口 
    BIND_WECHATAPI :BASEURL + '/weixin_open/bind' ,// 绑定微信接口 
   LOGIN_WECHATAPI :BASEURL + '/weixin_open/login/qrcode' ,// 登陆时获取的地址 
   LOGIN_WECHAT :BASEURL + '/weixin_open/web/login' ,// 登录微信接口 
   UNBIND_WECHAT :BASEURL + '/weixin_open/unbind ' ,// 解绑微信接口 
   CHECK_PWD :BASEURL + '/user/checkpassword  ' ,// 检查是否需要输入原密码 
    /*
        * 用户
     */
    USER_INFO :BASEURL + '/user/info' ,//查询用户信息
    USER_DATE :BASEURL + '/user/update' ,//修改用户信息
    USER_CENTER :BASEURL + '/user/centerInfo' ,//用户首页接口
    USER_COLLECT :BASEURL + '/usercollect/list' ,//用户收藏列表
    USER_BROWSER :BASEURL + '/userbrowsehistory/list' ,//用户浏览列表接口
    USER_APPOINTMENT :BASEURL + '/userorder/list' ,//我的预约列表接口
    USER_SERVICELIST :BASEURL + '/serviceinfo/list' ,//服务记录列表接口
    USER_EVALUATION :BASEURL + '/serviceinfo/evaluation ' ,//星星评价接口
    USER_MESSAGEBACK :BASEURL + '/messagefeedback/list ' ,//留言返回列表接口
}
module.exports = {URL,BASEURL}
